@import "~src/styles/variables/core/variables";
@import "../variables";

$colors: (
  $font: $color-K,
  $background: $color-I,
  $box-shadow: $color-K,
);

&.primary {
  @include generate-schema-dialog-color($colors);
}
