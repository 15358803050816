@import "~src/styles/functions";
@import "~src/styles/mixins";
@import "~src/styles/variables/core/variables";

$font-color: $color-I;

app-qa-list-custom {
  .ow-dialog.primary .bottom-bar .bar-button::before {
    bottom: multiply-size(6px);
    height: multiply-size(22px);
  }


  .window-a {
    padding: multiply-size(30px) multiply-size(38px) multiply-size(28px) multiply-size(38px);
  }

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .try-number {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: multiply-size(10px);
    @include mikado;
    @include text-stroke;
    font-size: multiply-size(10px);
    margin: 0;
  }

  .title {
    width: 100%;
    text-align: center;
    @include text-stroke;
    @include mikado;
    font-size: multiply-size(14px);
    color: $font-color;

    &.quiz {
      margin-bottom: multiply-size(15px);
    }

    &.margin-top {
      margin-top: multiply-size(90px);
    }
  }

  .description {
    height: multiply-size(28px);
    font-size: multiply-size(12px);
    width: 100%;
    text-align: center;
    margin-bottom: multiply-size(15px);
    @include archivo-narrow;
  }

  .duration {
    margin-bottom: multiply-size(20px);
    @include mikado;
    font-size: multiply-size(10px);
    width: 100%;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
  }

  .icon-data {
    position: absolute;
    bottom: multiply-size(25px);
    right: multiply-size(57px);
  }

  .try-number {
    color: $font-color;
  }

  .buttons {
    display: flex;
    justify-content: center;

    button {
      margin: 0 multiply-size(5px);
    }
  }

  .margins {
    margin-bottom: multiply-size(6px);
    margin-top: multiply-size(10px);
  }
}
