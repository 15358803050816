@import "~src/styles/functions";
@import "~src/styles/mixins";
@import "~src/styles/variables/core/variables";

app-hud-resources {
  @import "variables";

  //  ------------------------
  //  PLAYER CURRENCY BALANCES
  //  ------------------------
  pointer-events: all;
  position: absolute;
  right: 0;
  top: 0;
  padding: multiply-size(1px) multiply-size(4px) 0 0;
  z-index: 1;

  #{$context-desktop} & {
    --multiply: 2;
  }

  #{$context-tablet} & {
    --multiply: 1.5;
  }

  button {
    &.bar {
      width: multiply-size(76px);
      height: multiply-size(24px);
      background-color: $bgc-bar;
      border: multiply-size(2px) solid $border;
      border-radius: multiply-size(6px);
      font-size: multiply-size(10px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      @include myriad;
      font-weight: 700;
      margin: multiply-size(8px) 0;
      color: $font-color;
      padding-right: multiply-size(4px);
    }

    //  Div for currency icon
    .icon {
      width: multiply-size(21px);
      height: multiply-size(20px);
      background-color: $bgc-icon;
      border-top-left-radius: multiply-size(4px);
      border-bottom-left-radius: multiply-size(4px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.initial-cursor {
      cursor: initial;
    }
  }

  .tooltip-container {
    font-weight: normal;
  }

  @import "custom/style";
}
