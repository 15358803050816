@import "~src/styles/functions";
@import "~src/styles/variables/core/variables";
@import "~src/styles/mixins";

app-chest {
  .ow-dialog {
    &.dialog-mobile {
      .dialog-inner {
        @include futura-heavy;
        padding-top: multiply-size(60px);
        justify-content: space-between;

        & > *, .action-buttons {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .section-header {
          width: 100%;
        }
      }
    }
  }

  .message-image {
    width: multiply-size(255px);
    height: multiply-size(110px);
    margin-bottom: multiply-size(16px);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .title {
    font-size: multiply-size(16px);
    text-align: center;
  }

  .description {
    font-size: multiply-size(10px);
    text-align: center;
  }
}
