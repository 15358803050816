registration-confirm {
  @import "../../../../basic/styles/styles";
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    margin-bottom: 0;
  }

  .title {
    text-align: center;
  }

  .control.error {
    input {
      border-color: #FF0000 !important;
      color: #FF0000 !important;
    }
  }

  .custom-checkbox {
    input {
      cursor: pointer !important;
    }
  }

  .alert {
    @include archivo-narrow;
    font-size: multiply-size(12px);
    text-align: center;
    margin-top: multiply-size(6px);
    color: #FF0000;
    font-weight: bold;
  }

  .error {
    color: #FF0000 !important;
  }

  .text-error {
    text-align: center;
    font-size: multiply-size(12px);
    padding: 8px;
    @include archivo-narrow;
  }

  .text-hint{
    @include archivo-narrow;
    text-align: center;
    font-size: multiply-size(12px);
    padding: 8px;
    text-align: center;
    margin-top: multiply-size(6px);
    font-weight: bold;
  }
}
