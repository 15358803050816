m-ui-costs {
  .costs-container {
    flex-wrap: unset !important;
  }
}

m-ui-close-button button.exit i {
  color: #FFFFFF !important;
  @include text-stroke();
}

