@import "~src/styles/functions";
@import "~src/styles/mixins";
@import "~src/styles/variables/core/variables";

m-ui-exp-up {
  display: flex;
  align-items: center;

  i {
    margin-right: multiply-size(2px);
  }

  span {
    font-size: multiply-size(12px);
    line-height: multiply-size(12px);
    @include myriad;
    font-weight: bold;
  }

  &.small {
    width: multiply-size(25px);
    height: multiply-size(25px);
    background-color: $color-H;
    border-radius: multiply-size(100px);
  }

  @import "custom/style";
}
