@import "~src/styles/functions";
@import "~src/styles/mixins";
@import "~src/styles/variables/core/variables";

app-hud-player {
  @import "variables";

  width: multiply-size(60px);
  height: multiply-size(60px);
  position: relative;
  background: var(--asset--ui-bar-player-png) no-repeat;
  background-size: contain;
  display: block;
  margin-right: multiply-size(6px);

  .player-bar-container {
    width: 100%;
    height: 100%;
    background: var(--asset--ui-bar-player-up-png) no-repeat;
    background-size: contain;
    position: relative;
    z-index: 2;

    m-ui-game-date {
      z-index: 3;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
    }

    .avatar, .user-options {
      width: multiply-size(38px);
      height: multiply-size(38px);
      position: absolute;
      top: multiply-size(11px);
      left: multiply-size(11px);
      border-radius: multiply-size(30px);
    }

    .avatar {
      z-index: -1;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;

      &.default {
        background-image: var(--asset--ui-avatar-temp-png);
      }
    }

    .user-options {
      opacity: 0;
      transition: 0.4s;
      background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.79) 0%, rgba(88, 130, 162, 0) 70%, rgba(125, 185, 232, 0) 100%);
      z-index: 5;
      color: $color-I;
      cursor: pointer;
      font-size: multiply-size(10px);

      i {
        opacity: 0.9;
      }

      &:hover {
        opacity: 1;
      }

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        cursor: pointer;
        opacity: 0;
      }
    }

    .level-star {
      width: multiply-size(18px);
      height: multiply-size(18px);
      background: $level-star-bgc;
      background-size: contain;
      position: absolute;
      @include text-stroke;
      @include myriad;
      font-weight: bold;
      color: $level-star-color;
      @include absolute-center-horizontal;
      padding-top: multiply-size(2px);
      box-sizing: border-box;
      border-radius: multiply-size(100px);
      bottom: multiply-size(-2px);
      font-size: multiply-size(9px);
    }
  }

  #circle {
    position: absolute;
    top: multiply-size(2px);
    left: multiply-size(2px);
    z-index: 1;
    transform: rotate(90deg);
    width: multiply-size(56px);
    height: multiply-size(56px);

    circle {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 1s linear;
      stroke: $circle-stroke-color;
    }
  }

  @import "custom/styles";
}
