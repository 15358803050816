@import "~src/styles/functions";
@import "~src/styles/mixins";
@import "~src/styles/variables/core/variables";

$font-color: $color-I;

app-qa-list-view {
  .stock-g {
    .icon {
      width: multiply-size(16px) !important;
      height: multiply-size(16px) !important;
    }
  }

  button.checkbox {
    border: multiply-size(2px) solid #333333 !important;
    width: multiply-size(24px);
    height: multiply-size(24px);
    border-radius: multiply-size(30px);
  }

  button.checkbox.active::after {
    width: multiply-size(14px);
    height: multiply-size(14px);
    background: #333333;
  }

  .window-a {
    padding: multiply-size(18px) multiply-size(34px) multiply-size(16px) multiply-size(34px);
  }

  .container {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .container-filter {
    display: flex;
    align-items: center;
    height: 10%;
    justify-content: center;
    margin-bottom: multiply-size(15px);
  }

  .each-filter {
    display: flex;
    align-items: center;
    margin-right: multiply-size(10px);

    button {
      margin-right: multiply-size(8px);
    }

    span {
      @include myriad;
      font-weight: bold;
      font-size: multiply-size(10px);
      color: $color-H;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;

    tbody {
      @include archivo-narrow();
      color: $color-H;
      font-size: multiply-size(9px);

      tr {
        height: multiply-size(20px);
        cursor: pointer;

        &:nth-child(odd) {
          td {
            background: #F3F2EF;
          }
        }
      }

      td {
        &.td-name {
          width: 80%;
          padding-left: multiply-size(10px);

          .name {
            font-weight: bold;
          }
        }

        &.td-prize {
          padding-right: multiply-size(10px);

          div {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            span {
              margin-right: multiply-size(6px);
            }
          }
        }
      }
    }

    img {
      width: multiply-size(20px);
      height: multiply-size(20px);
    }
  }

  .opacity {
    opacity: 0.6;
  }
}
