@import "~src/styles/functions";
@import "~src/styles/variables/core/variables";
@import "~src/styles/mixins";

app-building-upgrade {
  .window-a {
    padding: multiply-size(30px) multiply-size(26px) multiply-size(22px) multiply-size(26px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    color: $color-K;
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    opacity: 0;
  }

  .opacity-1 {
    opacity: 1;
  }

  .level {
    font-size: multiply-size(12px);
    text-transform: uppercase;
    @include myriad;
    font-weight: bold;
    margin-top: multiply-size(-18px);
    margin-bottom: multiply-size(5px);
    text-align: center;
    color: $color-K;

    .color-secondary {
      color: $color-B;
      @include text-stroke;
    }
  }

  .page {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .inner-html-description {
      text-align: center;
      padding: 0 multiply-size(50px);
      box-sizing: border-box;
      font-size: multiply-size(12px);
      @include archivo-narrow;
    }
  }

  m-building-upgrade-diff-production,
  m-building-upgrade-diff-parameter {
    display: flex;
    justify-content: center;
    width: 33.3333%;
  }

  swiper {
    flex: 1;
  }

  m-ui-exp-up {
    position: absolute;
    left: multiply-size(7px);
    top: multiply-size(7px);
    color: $color-K;
  }

  .arrows-slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: multiply-size(-10px);
    z-index: 10;

    i {
      &.left, &.right {
        cursor: pointer;
        padding: multiply-size(5px);

        &.hide {
          visibility: hidden;
        }
      }

      &.left {
        transform: translate(multiply-size(-5px), multiply-size(5px));
      }

      &.right {
        transform: translate(multiply-size(5px), multiply-size(5px));
      }
    }
  }

  .title-small {
    font-size: multiply-size(11px);
    font-weight: bold;
    margin: 0 0 multiply-size(8px) 0;
    @include archivo-narrow;
  }

  .unlocked-buildings {
    flex-direction: column;

    m-building-thumbnail {
      margin-right: multiply-size(10px);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .container {
    min-height: multiply-size(50px);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;

    .parameter {
      width: multiply-size(40px);
      margin: 0 multiply-size(5px);

      .value {
        @include futura-heavy;
        font-size: multiply-size(8px);
        width: 100%;
        text-align: center;
        margin-top: multiply-size(-5px);
        @include text-stroke($color-K);
        @include drop-shadow($color-K, 2);
      }
    }
  }

  .costs-container {
    justify-content: center;
  }

  .upgrade-label {
    display: flex;
    position: relative;
  }

  .production-container {
    background-color: $color-K;
    position: absolute;
    z-index: -1;

    &.arrow {
      position: relative;
      z-index: -1;

      &:after {
        background-color: $color-K;
        clip-path: polygon(42% 0%, 100% 50%, 42% 100%, 0% 100%, 0% 0%);
        width: multiply-size(12px);
        height: 100%;
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        z-index: -1;
        top: 0;
        right: multiply-size(4px);
        transform: translateX(100%);
      }
    }

    .value-diff {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: multiply-size(2px) 0 0 0;
    }
  }

  .level {
    span {
      color: $color-B;
      @include text-stroke;
    }
  }

  .texts-container {
    .text {
      color: $color-K;
    }
  }

  .hide {
    opacity: 0;
    pointer-events: none;
  }

  @import "custom/style";
}
