@import "~src/styles/functions";

app-core-video {
  $window-height: multiply-size(302px);

  .global-dialog {
    width: multiply-size(510px);
    height: $window-height;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .video-container {
      display: flex;
      justify-content: center;

      .exit-bar {
        margin-left: multiply-size(15px);
      }

      #ytplayer {
        width: 100%;
        height: $window-height;
      }

      video {
        margin-left: multiply-size(30px);
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
