@import "~src/styles/functions";

$media-s: 360px; // no-multiply-size
$media-md: 768px; // no-multiply-size
$media-lg: 1024px; // no-multiply-size
$media-xlg: 1200px; // no-multiply-size
$media-xxlg: 1920px; // no-multiply-size

$color-dark: #4C5359;
$color-light: #6F757B;

@import "custom/variables";
