@import "~src/styles/functions";
@import "~src/styles/mixins";
@import "~src/styles/variables/core/variables";

.ow-dialog {
  &.window-a {
    padding: multiply-size(10px) multiply-size(28px) multiply-size(8px) multiply-size(30px);
    box-sizing: border-box;
  }
}

table {
  border-spacing: 0;
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;

  tr {
    th, td {
      padding: multiply-size(1px) multiply-size(3px);
      text-align: left;
      height: multiply-size(15px);

      &:first-child {
        padding-left: multiply-size(8px);
      }

      &.column-prizes {
        width: multiply-size(50px);
        min-width: multiply-size(50px);
      }
    }
  }

  thead {
    th {
      @include archivo-narrow;
      font-size: multiply-size(8px);
      font-weight: normal;
    }
  }

  tbody {
    td {
      @include mikado;
      font-size: multiply-size(10px);
      font-weight: bold;
      border-bottom: multiply-size(1px) solid rgba($color-H, 0.4);
      max-width: multiply-size(170px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    tr {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }

  .hide-element {
    visibility: collapse;

    td, th {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      line-height: 0 !important;
      height: 0 !important;
      border: none !important;
      overflow: hidden !important;

      .prizes, .avatar {
        height: 0 !important;
        overflow: hidden !important;
        border: none !important;
      }
    }
  }
}

.my-position {
  table {
    tbody {
      tr {
        td {
          border-bottom: none;
        }
      }
    }
  }
}

.prizes {
  display: flex;
  justify-content: flex-end;

  & > * {
    margin: 0 multiply-size(3px);
  }

  .value {
    @include mikado('!important');
    font-size: multiply-size(10px) !important;
    font-weight: bold;
  }
}

.avatar {
  box-sizing: border-box;
  border: multiply-size(1px) solid black;
  width: multiply-size(15px);
  height: multiply-size(15px);
  border-radius: multiply-size(15px);
  background: var(--asset--ui-avatar-temp-png) no-repeat;
  background-size: contain;
}

.rank-details-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.my-position-container {
  margin: multiply-size(5px) 0;

  label {
    @include mikado;
    font-size: multiply-size(8px);
    padding-left: multiply-size(8px);
    padding-right: multiply-size(8px);
    margin-bottom: multiply-size(3px);
    display: flex;
    justify-content: space-between;
  }

  .my-position {
    box-shadow: 0 0 0 multiply-size(2px) $color-K;
    border-radius: multiply-size(10px);
    padding: multiply-size(1px) 0;
  }
}

.no-items {
  height: 100%;
  font-size: multiply-size(12px);
  font-weight: bold;
  @include mikado;
}

perfect-scrollbar {
  &.height-auto {
    height: auto;
  }
}

perfect-scrollbar {
  width: 100% !important;
}

.my-position-container {
  td {
    border-bottom: none !important;
  }
}

.filter-container {
  button {
    &.checkbox {
      border-color: #000000 !important;
    }
  }
}

.filter-container {
  margin-top: multiply-size(10px);
  display: flex;
  align-items: center;

  .each-filter {
    display: flex;
    align-items: center;
    margin-right: multiply-size(10px);

    button {
      margin-right: multiply-size(8px);
    }

    span {
      @include mikado;
      font-weight: bold;
      font-size: multiply-size(10px);
    }
  }
}

.my-position-container {
  .my-position {
    border-radius: multiply-size(8px);
    background: #ffffff;
  }
}

perfect-scrollbar {
  .ps-content {
    padding-right: multiply-size(10px);
  }
}
