@import "~src/styles/functions";
@import 'mixins';
@import 'variables/core/variables';

.tabs {
  position: relative;
  width: 100%;
  height: multiply-size(41px);

  &.middle, &.long {
    width: 100%;

    .separator {
      position: absolute;
      bottom: 0;
      z-index: 2;
      width: 100%;
    }

    .tab {
      width: multiply-size(282px);
      height: multiply-size(41px);
      line-height: multiply-size(41px);
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      @include mikado-black;
      font-size: multiply-size(18px);
      text-align: center;

      &:nth-child(3) {
        left: auto;
        right: 0;
      }

      &.active {
        z-index: 3;
      }

      &:not(.active) {
        cursor: pointer;
        color: rgba($color-base, 0.7);
      }
    }
  }

  &.long {
    .tab {
      width: multiply-size(387px);

      &.active {
      }
    }
  }
}
