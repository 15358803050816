@import "~src/styles/functions";
@import "~src/styles/variables/core/variables";
@import "~src/styles/mixins";

app-message-details-chest-failed {
  @import "../../styles/style";
  @import "variables";

  .ow-dialog {
    &.dialog-mobile {
      .dialog-inner {
        @include futura-heavy;
        padding-top: multiply-size(60px);
        justify-content: space-between;

        & > * {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .action-buttons {
          justify-content: center;
        }

        .section-header {
          width: 100%;
        }
      }
    }
  }

  .message-image {
    width: multiply-size(225px);
    height: multiply-size(110px);
    margin-bottom: multiply-size(16px);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  @import "custom/style";
}
