@import "~src/styles/functions";

app-m-qa-summary {
  .score-and-prizes {
    display: flex;
    justify-content: center;
    align-items: center;

    & > * {
      margin: 0 multiply-size(10px);
    }
  }
}
