@import "~src/styles/functions";
.progressbar {
  .progressbar-fill {
    max-width: 100%;
    height: 100%;
    border-radius: multiply-size(30px);

    &.one {
      background: linear-gradient(to bottom, #469c92 50%, #2d857b 50%);
    }

    &.two {
      background: linear-gradient(to bottom, #a37f12 50%, #8a6800 50%);
    }

    &.three {
      background: linear-gradient(to bottom, #acdb40 50%, #95c427 50%);
    }

    &.ten {
      background: linear-gradient(to bottom, #ff5959 50%, #d94646 50%);
    }

    &.eleven {
      background: linear-gradient(to bottom, #7eccc3 50%, #68b7ae 50%);
    }
  }
}

@import "../custom/progressbars";
