@import "~src/styles/functions";
@import "~src/styles/mixins";


app-messages {
  @import "../../styles/style";
  @import "variables";

  .ow-dialog {
    .menu-right {
      justify-content: flex-end;
      align-items: flex-end;
      box-sizing: border-box;
    }
  }

  .messages {
    .message {
      display: flex;
      align-items: center;
      height: multiply-size(40px);
      border-bottom: multiply-size(1px) solid $color-K;

      .image {
        width: multiply-size(25px);
        height: multiply-size(25px);
        margin-right: multiply-size(10px);

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      .content {
        flex: 1;
        line-height: multiply-size(12px);
        overflow: hidden;
        cursor: pointer;

        .title, .teaser {
          margin: 0;
          @include text-ellipsis;
          padding-right: multiply-size(20px);
          font-weight: bold;
        }

        .title {
          @include myriad;
          font-size: multiply-size(12px);
          text-align: left;
        }

        .teaser {
          font-weight: bold;
          font-size: multiply-size(10px);
          @include archivo-narrow;
        }

        &.readed {
          .title, .teaser {
            color: $message-color-read;
          }

          .title {
            @include myriad;
          }

          .teaser {
            font-weight: normal;
          }
        }
      }

      .trash {
        font-size: multiply-size(10px);
        cursor: pointer;
        margin-right: multiply-size(3px);
        padding: multiply-size(5px);
      }
    }
  }

  .no-item {
    text-align: center;
    font-size: multiply-size(12px);
    @include mikado;
    color: rgba($color-K, 0.9);
  }

  @import "custom/style";
}
