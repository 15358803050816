@import "~src/styles/functions";
@import "~src/styles/mixins";
@import "~src/styles/variables/core/variables";

app-m-building-daily-production {
  .window-a {
    padding: multiply-size(30px) multiply-size(38px) multiply-size(22px) multiply-size(38px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .container {
    flex-direction: column;
  }

  .title {
    .building-level {
      display: inline;
      text-transform: none;
    }
  }

  .building-icon-container {
    width: multiply-size(100px);
    position: relative;
    z-index: 0;

    &:before {
      content: '';
      position: absolute;
      width: multiply-size(214px);
      height: multiply-size(212px);
      z-index: -1;
      left: multiply-size(107px, '50% - ');
      top: multiply-size(106px, '50% - ');
      opacity: 0;
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .building-desc, .building-additional-desc {
    font-size: multiply-size(12px);
    text-align: center;
    margin: 0;
    z-index: 2;
    @include myriad-cond;
  }

  .building-desc {
    padding-top: multiply-size(10px);
    @include myriad-cond;
  }

  .parameters {
    display: flex;
    justify-content: center;

    m-ui-stock-other {
      margin-right: multiply-size(20px);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .building-additional-desc, .parameters {
    margin-top: multiply-size(16px);
  }

  @import "custom/style";
}
