@import "../../../../../../../styles/functions";
@import "~src/styles/mixins";

app-identifier {
  height: 100vh;
  width: 100vw;

  &:after {
    content: "";
    position: fixed;
    background: var(--asset--ui-background-png) no-repeat center center fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background-image: url("https://green-town.o-w.dev/main/assets-gfx/ui/background.png?v=1082b1fbc412c616d39ef7c58151646741b2a03d");
  }

  #{$context-desktop} & {
    --multiply: 2;
  }

  #{$context-tablet} & {
    --multiply: 1.5;
  }

  @import "../../../../basic/styles/styles";
  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    position: relative;
    z-index: 0;
    width: calc(290px * var(--multiply));
    max-width: unset;
    margin-top: calc(200px * var(--multiply));
    height: multiply-size(309px);

    &.identifier {
      margin-top: calc(42px * var(--multiply));
      width: calc(300px * var(--multiply));
      height: calc(276px * var(--multiply));
      padding: calc(6px * var(--multiply)) 0 calc(13px * var(--multiply));

      .logo {
        background: var(--asset--ui-logo-png) center no-repeat;
        margin: 0 auto multiply-size(12px);
        background-size: contain;
        width: auto;
        height: auto;
      }

      h5 {
        @include mikado;
        font-size: multiply-size(13px);
        line-height: multiply-size(17px);
        text-align: center;
        font-weight: bold;
        letter-spacing: 0;
        margin: 0 0 multiply-size(16px);
      }

      form {
        .control {
          margin: 0 auto multiply-size(40px);
          width: 66.6%;

          &.control-checkbox {
            &.remember-me-container {
              margin-top: multiply-size(20px);
              align-items: center;

              label {
                padding: 0;
                padding-left: multiply-size(8px);
              }
            }
          }

          input {
            height: multiply-size(30px);
          }

          label {
            text-align: center;
            margin-bottom: multiply-size(2px);;
            padding-left: 0;
            font-weight: normal;
          }
        }
      }

      .offer-text {
        @include archivo-narrow;
        font-size: multiply-size(9px);
        line-height: multiply-size(10px);
        margin: 0 0 multiply-size(20px);
        text-align: center;

        a {
          color: #3D81F2;
          text-decoration: none;
        }
      }

      .control {
        margin: 0 auto multiply-size(40px);
      }

      label {
        font-size: multiply-size(9px);
      }

      .bottom-bar {
        box-sizing: border-box;
        width: 100%;
        position: absolute;
        height: calc(55px * var(--multiply));
        bottom: calc(-28px * var(--multiply));
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        padding: 0 calc(10px * var(--multiply));

        .bar-button {
          position: relative;
          box-sizing: border-box;
          height: 100%;
          display: flex;
          align-items: center;
          margin: 0 calc(3px * var(--multiply));
          width: calc(150px * var(--multiply));
          padding: calc(6px * var(--multiply));

          &::before {
            content: "";
            display: block;
            box-sizing: border-box;
            position: absolute;
            background-color: #fff;
            border: calc(2px * var(--multiply)) solid #000000;
            z-index: -1;
            bottom: calc(7px * var(--multiply));
            left: 0;
            width: 100%;
            height: calc(21px * var(--multiply));
            border-radius: 0 0 calc(14px * var(--multiply)) calc(14px * var(--multiply));
            border-top-style: none;
            box-shadow: 0 calc(-2px * var(--multiply)) 0 0 #fff;
          }

          button {
            font-size: multiply-size(11px);
            height: multiply-size(31px);
            text-transform: none;
          }
        }
      }
    }

    &.not-found,
    &.congratulations {
      position: relative;
      width: calc(420px * var(--multiply));
      height: multiply-size(193px);
      margin-top: multiply-size(140px);

      @media (max-width: 545px){
        height: multiply-size(200px);
        width: multiply-size(275px);
      }

      .logo {
        background-image: var(--asset--ui-logo_big-png);
        position: absolute;
        top: multiply-size(-110px);
        width: multiply-size(230px);
        height: multiply-size(198px);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        left: 0;
        right: 0;
        margin: auto;

        #{$context-desktop} & {
          background-size: inherit;
        }
      }

      .description {
        display: block;
        margin: multiply-size(5px) 0;

        p {
          margin: 0;
        }
      }

      p {
        font-family: "Montserrat", serif;
        margin: 0;
        text-align: center;
        font-size: multiply-size(20px);
        color: #20872e;
        font-weight: 600;
      }
    }

    &.congratulations {
      padding: multiply-size(107px) 0 multiply-size(40px);

      p {
        margin: 0;
        text-align: center;
        font-size: multiply-size(20px);
      }
    }

    &.not-found {
      padding: multiply-size(80px) multiply-size(40px) multiply-size(30px);

      .title {
        margin: multiply-size(2px) 0 calc(6px * var(--multiply));
        font-size: calc(16px * var(--multiply));
      }

      .description {
        font-size: multiply-size(9px);
        margin-bottom: multiply-size(15px);
      }

      button {
        width: multiply-size(150px);
        height: multiply-size(30px);
        font-size: multiply-size(9px);
        margin: 0 auto;
      }
    }

  }

  .logo {
    min-height: multiply-size(100px);
    min-width: multiply-size(186px);
  }
}
