@import "~src/styles/functions";

app-hud-knowledge {
  position: absolute;
  z-index: 1;
  left: multiply-size(5px);
  bottom: multiply-size(5px);

  @media (max-height: 600px) {
    left: multiply-size(50px);
  }

  #{$context-desktop} & {
    --multiply: 2;
  }

  #{$context-tablet} & {
    --multiply: 1.5;
  }

  ow-object-svg {
    width: multiply-size(68px);
    height: multiply-size(68px);
  }

  &:hover {
    filter: var(--filter-hover);
  }

  button {
    @include notificationBounce;
    position: relative;
  }
}
