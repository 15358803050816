@charset "UTF-8";
.ng-dropdown-wrapper {
  display: inline-block;
  position: relative
}

.ng-dropdown-wrapper input[type=text] {
  width: 90%;
  border: none;
  outline: 0;
  text-transform: capitalize
}

.ng-dropdown-wrapper #caret {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 999
}

.ng-dropdown-wrapper .icon::after {
  content: "▼";
  text-align: center;
  pointer-events: none
}

.ng-dropdown-wrapper .ng-dropdown-menu {
  display: none;
  position: absolute;
  top: 102%;
  left: 0;
  right: 0;
  list-style: none;
  overflow: auto;
  z-index: 9999
}

.ng-dropdown-wrapper .ng-dropdown-menu li span {
  text-transform: capitalize;
  transition: all .3s ease-out
}

.ng-dropdown-wrapper.active #caret {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg)
}

.ng-dropdown-wrapper.active .ng-dropdown-menu {
  display: block
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: .7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
