@import "~src/styles/functions";
@import "~src/styles/mixins";

app-hud-offset-time {
  .time-test {
    position: absolute;
    left: 0;
    bottom: 0;
    background: #ffffff;
    border-top-right-radius: multiply-size(10px);
    padding: multiply-size(5px) multiply-size(15px);
    pointer-events: all;
    display: flex;
    flex-direction: column;
    font-size: multiply-size(14px);
    height: 0;
    transform: translateX(-70%);
    z-index: 5;

    p, .flex-center {
      transform: scale(0);
    }

    p {
      margin: multiply-size(3px) 0;
    }

    button {
      background: #ffffff;
      padding: multiply-size(3px);
      margin: multiply-size(3px);
      border: multiply-size(1px) solid black;
      color: black;

      &.button-toggle {
        position: absolute;
        top: 0;
        font-size: multiply-size(20px);
        border: none;
        padding: multiply-size(10px);
        transform: translateY(-100%);
        right: multiply-size(30px);
      }
    }

    &.active {
      height: auto;
      transform: translateX(0);

      .button-toggle {
        i {
          transform: scale(-1);
        }
      }

      p, .flex-center {
        transform: scale(1);
      }
    }
  }

  .zoom {
    margin-left: multiply-size(10px);
  }
}
