@import "~src/styles/functions";
@import "~src/styles/mixins";
@import "~src/styles/variables/core/variables";

app-custom-hud-menu {
  .main-buttons-container {
    display: flex;
    flex-direction: column;

    .buttons-vertical {
      display: flex;
    }

    .main-button {
      height: multiply-size(40px);
      width: multiply-size(40px);
      margin-bottom: multiply-size(6px);
      pointer-events: all;
      @include notificationBounce;
      position: relative;

      &:hover {
        filter: var(--filter-hover);
      }

      &.anim {
        transform: translateX(-115%) scale3d(0, 1, 1);
        pointer-events: none;
        opacity: 0;

        &.active {
          opacity: 1;
          pointer-events: initial;
          transform: translateX(0) scale3d(1, 1, 1);
        }
      }

      ow-object-svg {
        width: 100%;
        height: 100%;
      }
    }

    .buttons-vertical > .buttons-vertical {
      .main-button {
        transition: 0.5s;
        margin-left: multiply-size(6px);
      }
    }
  }
}
