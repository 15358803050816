@import "~src/styles/functions";
@import "~src/styles/mixins";
@import "~src/styles/variables/core/variables";

app-city-character-custom {
  .window-a {
    padding: multiply-size(20px) multiply-size(36px) multiply-size(20px) multiply-size(36px);
  }

  .top-description {
    padding: 0 multiply-size(66px);
    margin-bottom: multiply-size(20px);
    @include archivo-narrow;
    font-size: multiply-size(10px);
    text-align: center;
  }

  .parameters-container {
    .parameter {
      display: flex;

      .image {
        min-width: multiply-size(38px);
        width: multiply-size(38px);
        height: multiply-size(38px);
        margin-right: multiply-size(8px);

        &:not(:last-child) {
          margin-bottom: multiply-size(12px);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .parameter-details {
        flex: 1;

        .header {
          display: flex;
          align-items: center;
          margin-bottom: multiply-size(3px);
          justify-content: space-between;

          .title {
            font-size: multiply-size(12px);
            @include mikado;
            font-weight: bold;
          }

          .prize-icons-container {
            display: flex;
            align-items: center;

            .value {
              font-size: multiply-size(12px);
              @include mikado;
              font-weight: bold;
            }

            .icon-ranks {
              margin-left: multiply-size(9px);
              border-left: 1px solid black; // no-multiply-size
              padding-left: multiply-size(43px);
              font-size: multiply-size(12px);

              &.hide {
                visibility: hidden;
              }
            }
          }
        }

        .description {
          font-size: multiply-size(8px);
          @include archivo-narrow;
          padding-right: multiply-size(55px);
        }
      }
    }
  }
}
