@import "~src/styles/functions";
@import "~src/styles/variables/core/variables";
@import "~src/styles/mixins";

app-building-categories-info {
  .window-d {
    position: relative;
    height: multiply-size(240px) !important;
    width: multiply-size(474px) !important;
    min-height: multiply-size(240px) !important;
    min-width: multiply-size(474px) !important;
    padding-top: multiply-size(13px) !important;

    .close-icon {
      display: inline;
      position: absolute;
      top: multiply-size(3px);
      right: multiply-size(3px);
    }

    p {
      &.title {
        margin: calc(5px * var(--multiply)) 0 calc(14px * var(--multiply)) 0;
        font-size: multiply-size(12px);
        @include myriad;
        font-weight: bold;
        color: black;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .categories-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      width: multiply-size(310px);
      margin: auto;
      gap: multiply-size(6px) multiply-size(2px);
    }

    .description {
      @include archivo-narrow;
      margin: multiply-size(10px) 0 multiply-size(4px);
      text-align: center;
      font-size: multiply-size(12px);
      max-height: multiply-size(220px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      float: left;
      font-weight: bold;

      img {
        width: multiply-size(40px);
        height: multiply-size(40px);
        object-fit: contain;
        margin-right: multiply-size(2px);
        margin-bottom: multiply-size(4px);
      }
    }

    m-ui-close-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
