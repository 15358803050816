@import "~src/styles/functions";

app-photo-details {
  @import "../../styles/style";

  .image-dialog {
    box-sizing: border-box;
    background: #ffffff;
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: multiply-size(20px);

    @media only screen and (min-width: $media-md) {
      height: 95vh;
      width: multiply-size(620px);
      padding: multiply-size(40px);
      padding-right: multiply-size(32px);
      border-radius: multiply-size(15px);
    }

    @media only screen and (min-width: $media-lg) {
      width: multiply-size(647px);
    }

    @media only screen and (min-width: $media-xxlg) {
      width: multiply-size(791px);
    }

    .exit-button {
      width: multiply-size(46px);
      height: multiply-size(46px);
      position: absolute;
      top: 0;
      right: 0;
      font-size: multiply-size(22px);
      color: $color-I;
      z-index: 1;
      @media only screen and (min-width: $media-md) {
        color: $color-dark;
      }
    }

    .image-navigation {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      opacity: 0;
      z-index: 1;
      transition: 0.25s;
      transition-timing-function: ease-out;
      transform: translateY(0);
      pointer-events: none;

      @media only screen and (min-width: $media-md) {
        opacity: 1;

        button {
          pointer-events: all;
        }
      }

      &.show {
        transition: 0.25s;
        transition-timing-function: ease-out;
        transform: translateY(0);
        opacity: 1;

        button {
          pointer-events: all;
        }
      }

      button {
        border: multiply-size(1px) solid #DAE1E7;
        background: #ffffff;
        border-radius: multiply-size(50px);
        width: multiply-size(50px);
        height: multiply-size(50px);

        &.prev {
          transform: translate(-100%, -50%);
          left: multiply-size(60px);
          z-index: 1;
          @media only screen and (min-width: $media-md) {
            left: multiply-size(-16px);
          }
        }

        &.next {
          transform: translate(100%, -50%);
          right: multiply-size(60px);
          z-index: 1;
          @media only screen and (min-width: $media-md) {
            right: multiply-size(-16px);
          }
        }

        &.opacity-0 {
          cursor: initial;

          @media only screen and (max-width: $media-md) {
            pointer-events: none;
          }
        }

        i {
          font-size: multiply-size(28px);
          color: $color-dark;
        }
      }
    }

    .each-image-annotation {
      margin-top: multiply-size(10px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 0 multiply-size(24px);

      @media only screen and (min-width: $media-md) {
        padding: 0;
      }

      .right-container {
        display: flex;
        transform: translateX(multiply-size(7px));

        @media only screen and (min-width: $media-md) {
          transform: translateX(multiply-size(21px));
        }

        button {
          &.more-options {
            width: multiply-size(48px);
            height: multiply-size(48px);
            color: $color-dark;
            font-size: multiply-size(20px);
            margin-left: multiply-size(5px);
          }
        }

        .more-options-container {
          background: #ffffff;
          border: multiply-size(1px) solid $color-light;
          position: absolute;
          bottom: 0;
          right: multiply-size(20px);
          transform: translateY(100%);

          button {
            width: 100%;
            font-size: multiply-size(13px);
            @include roboto-cond;
            color: $color-dark;
            padding: multiply-size(10px);
            text-transform: none;
            white-space: nowrap;

            &:hover {
              background: #efefef;
            }

            &.delete {
              color: #ff0000;
            }

            i {
              margin-right: multiply-size(6px);
            }
          }

          a, a:hover, a:visited {
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }

    .each-image-author, .each-image-other-info, .each-image-date {
      color: $color-light;
      font-size: multiply-size(16px);
      @include roboto-cond;
      margin: 0;

      &.each-image-author, &.each-image-other-info {
        margin-bottom: multiply-size(8px);
        padding: 0 multiply-size(24px);

        @media only screen and (min-width: $media-md) {
          padding: 0;
        }
      }
    }

    .image-title {
      font-size: multiply-size(22px);
      color: $color-dark;
      @include roboto;
      font-weight: bold;
      margin: multiply-size(20px) 0 multiply-size(10px) 0;
      padding: 0 multiply-size(24px);

      @media only screen and (min-width: $media-md) {
        padding: 0;
      }
    }

    .image-description {
      color: $color-light;
      font-size: multiply-size(16px);
      @include roboto-cond;
      padding: 0 multiply-size(24px);

      @media only screen and (min-width: $media-md) {
        padding: 0;
      }
    }
  }
}

//@import "~src/styles/mixins";
//@import "~src/styles/variables/core/variables";
//
//$min-height: multiply-size(550px);
//
//@import "../../styles/variables";
//
//:host {
//  height: 90vh;
//  overflow: auto;
//  width: 100vw;
//  display: flex;
//  justify-content: center;
//
//
//  &::ng-deep {
//    .exit-bar {
//      position: absolute;
//      top: multiply-size(7px);
//      right: multiply-size(4px);
//
//      button > i {
//        font-size: multiply-size(20px);
//      }
//    }
//
//    .prev, .next {
//      z-index: 0;
//      display: flex;
//      justify-content: center;
//      box-sizing: border-box;
//      position: absolute;
//      top: calc(#{$min-height} / 2);
//
//      button {
//        border: none !important;
//        box-shadow: none !important;
//        background: none !important;
//
//        i {
//          font-size: multiply-size(22px);
//        }
//      }
//    }
//
//    .prev {
//      left: multiply-size(5px);
//    }
//
//    .next {
//      right: multiply-size(5px);
//    }
//  }
//
//  app-photo-like {
//    display: flex;
//    align-items: center;
//  }
//}
//
//.container {
//  width: multiply-size(800px);
//  max-width: multiply-size(800px);
//  display: flex;
//  justify-content: space-between;
//  overflow: auto;
//  background-color: $color-I;
//  flex-direction: column;
//  padding: multiply-size(40px);
//  position: relative;
//  color: $color-X;
//  border-radius: multiply-size(8px);
//  min-height: $min-height;
//  box-sizing: border-box;
//
//  .image {
//    margin: 0 auto;
//
//    img {
//      max-width: 100%;
//      height: 100%;
//      object-fit: contain;
//    }
//  }
//
//  p {
//    @include archivo-narrow;
//
//    &.user-info,
//    &.desc {
//      margin-top: 0;
//    }
//
//    &.date {
//      margin-bottom: 0;
//    }
//
//    &.title {
//      font-weight: bold;
//      font-size: multiply-size(20px);
//    }
//
//    &.desc {
//      font-size: multiply-size(18px);
//    }
//
//    &.title,
//    &.desc {
//      color: $color-K;
//    }
//  }
//
//  .top {
//    display: flex;
//    justify-content: space-between;
//    width: 100%;
//
//    .right {
//      display: flex;
//      @include archivo-narrow;
//
//      button.trash {
//        @include archivo-narrow;
//        text-transform: unset;
//        margin-right: multiply-size(10px);
//        font-size: multiply-size(16px);
//
//        i {
//          margin-left: multiply-size(4px);
//          font-size: multiply-size(14px);
//        }
//      }
//    }
//  }
//
//  .download {
//    margin-right: multiply-size(10px);
//    margin-bottom: 0;
//    cursor: pointer;
//    display: flex;
//    align-items: center;
//    text-decoration: none;
//
//
//    a {
//      text-decoration: none !important;
//      color: $color-X;
//    }
//  }
//
//  .fa-download {
//    margin-left: multiply-size(4px);
//  }
//}
//
//@import "../../styles/style";
