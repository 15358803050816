@import "~src/styles/functions";

.stock-d {
  .box {
    height: multiply-size(15px);
    border: multiply-size(1px) solid $color-H;
    padding-top: 0 !important;

    .value {
      @include text-stroke();
    }
  }
}

.stock-b {
  .value {
    font-size: multiply-size(12px);
  }
}

.stock-c {
  .value {
    font-size: multiply-size(14px);
  }
}

.stock-j {
  .value {
    color: $color-H;
    text-shadow: none;
  }
}

.business-card {
  @include flex-center;

  &.s1s2 {
    .icon {
      margin-right: multiply-size(1px);
      width: multiply-size(20px);
      height: multiply-size(20px);
    }

    .value {
      align-self: center;
      margin: 0;
    }
  }

  &.s5 {
    flex-direction: row-reverse;

    &:not(:last-child) {
      margin-bottom: multiply-size(1px);
    }

    .value {
      margin-right: multiply-size(1px);
    }
  }

  &.s5-sum {
    flex-direction: row-reverse;

    .value {
      @include mikado;
      font-size: multiply-size(10px);
      margin-left: multiply-size(5px);
      margin-right: multiply-size(1px);
    }

    .icon {
      margin-right: 0;
      width: multiply-size(15px);
      height: multiply-size(15px);
    }
  }

  .icon {
    margin-right: multiply-size(1px);
    width: multiply-size(14px);
    height: multiply-size(14px);
  }

  .value {
    margin-left: multiply-size(2px);
    font-size: multiply-size(10px);
    word-spacing: multiply-size(-2px);
    z-index: 2;
    @include archivo-narrow;
    color: #000000;
    text-shadow: none;
  }

  .fa-exclamation {
    font-size: multiply-size(10px);
  }
}

.stock-minigames-game {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    margin-right: multiply-size(1px);
    width: multiply-size(30px);
    height: multiply-size(30px);

    i {
      background-image: var(--asset--ui-icon-exclamation-small-png);
      width: multiply-size(8px);
      height: multiply-size(15px);
      background-size: contain;
      background-repeat: no-repeat;

      &.fa-exclamation::before {
        display: none !important;
      }
    }
  }

  .value {
    @include myriad();
    @include text-stroke();
    color: #ffffff;
    font-size: multiply-size(12px);
    margin-top: multiply-size(3px);
  }
}

.stock-minigames-list {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: multiply-size(30px);
    height: multiply-size(30px);
    margin-right: multiply-size(3px);
  }

  .value {
    @include myriad();
    font-size: multiply-size(12px);
    font-weight: bold;
    color: #ffffff;
    padding-top: multiply-size(2px);
  }
}
