@import "~src/styles/functions";
@import "mixins";

.ng-dropdown-wrapper {
  width: 100%;
  padding: multiply-size(12px) multiply-size(16px);
  box-shadow: inset 0 0 0 multiply-size(2px) rgba(0, 0, 0, 1);
  border-radius: multiply-size(15px);
  cursor: pointer;
  outline: none;
  user-select: none;
  color: #000000;
  background: #ffffff;
  box-sizing: border-box;

  &.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  input {
    cursor: pointer;
  }

  .ng-dropdown-menu {
    /* Styles */
    background: #fff;
    border: multiply-size(2px) solid #000000;
    border-top: none;
    box-sizing: border-box;
    border-bottom-left-radius: multiply-size(15px);
    border-bottom-right-radius: multiply-size(15px);
    max-height: multiply-size(400px);
    transform: translateY(multiply-size(-4px));
    margin: 0;
    padding: 0;
    /* Hover state */
    & > * {
      padding: multiply-size(12px) multiply-size(16px);
      text-decoration: none;
      background: #ffffff;

      &:hover {
        background: #eeeeee;
      }
    }
  }

  .icon {
    &::after {
      content: '\f0d7' !important;
      font-weight: bold;
      @include font-awesome;
      text-align: center;
    }
  }
}

@import "custom/dropdown";
