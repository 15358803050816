@import "~src/styles/functions";
@import "~src/styles/mixins";

m-building-row-production {
  @import "variables";

  .exit-bar {
    top: multiply-size(-3px) !important;
    right: multiply-size(-6px) !important;
  }

  m-ui-close-button {
    margin: 0;
  }

  .recipe {
    display: flex;
    position: relative;
    box-sizing: border-box;
    width: 100%;

    &.blur > *:not(m-ui-multi-stock) {
      filter: blur(multiply-size(2px));
    }
  }

  .production-container-row {
    display: flex;
    background-color: $bgc-arrow;
    position: absolute;
    z-index: -1;

    &.arrow {
      position: absolute;
      z-index: -1;
      height: multiply-size(14px);
      transform: translateY(100%);
      left: multiply-size(20px);
      top: multiply-size(-2px);
      right: multiply-size(14px);

      &:after {
        background-color: $bgc-arrow;
        clip-path: polygon(42% 0%, 100% 50%, 42% 100%, 0% 100%, 0% 0%);
        width: multiply-size(12px);
        height: multiply-size(14px);
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        z-index: -1;
        top: 0;
        right: multiply-size(3px);
        transform: translateX(100%);
      }
    }
  }

  .ingredient {
    margin-top: multiply-size(6px);
    margin-right: multiply-size(30px);
    position: relative;

    &:before {
      content: "\f067";
      position: absolute;
      font-size: multiply-size(8px);
      right: multiply-size(-15px);
      @include font-awesome;
      @include text-stroke;
      color: $sign-color;
      margin-top: multiply-size(8px);
    }

    &:last-child {
      margin-right: 0;

      &:before {
        content: none;
      }
    }
  }

  .fa-equals {
    margin-left: multiply-size(4px);
    margin-right: multiply-size(4px);
    @include text-stroke;
    color: $sign-color;
  }

  .fa-equals {
    font-size: multiply-size(10px);
    margin-top: multiply-size(14px)
  }

  @import "custom/style";
}
