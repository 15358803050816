@import "~src/styles/functions";
@import "variables/core/variables";
@import "mixins";

button {
  &.checkbox {
    width: multiply-size(26px);
    height: multiply-size(26px);
    border: multiply-size(2px) solid $color-K !important;
    border-radius: multiply-size(8px);
    background-color: $color-I;
    box-shadow: inset 0 multiply-size(3px) 0 0 #B8B3A4;
    margin-right: multiply-size(6px);
    position: relative;

    &.active {
      &:after {
        content: '';
        width: multiply-size(15px);
        height: multiply-size(15px);
        border-radius: 50%;
        background: $color-K;
        @include absolute-center;
      }
    }
  }
}

@import "custom/radio-buttons";
