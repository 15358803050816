@import "~src/styles/functions";
@import "~src/styles/mixins";

app-message-details-comics {
  @import "../../styles/style";
  @import "variables";

  .window-a {
    padding: multiply-size(19px) multiply-size(32px) multiply-size(19px);
  }

  .gallery-page {
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @import "custom/style";
}
