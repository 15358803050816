@import "~src/styles/functions";
@import "~src/styles/variables/core/variables";
@import "~src/styles/mixins";

m-ui-group-count {
  @include text-stroke;
  color: $color-I;
  padding: multiply-size(2px);

  .wrapper {
    display: flex;
    align-items: baseline;
  }

  i {
    font-size: multiply-size(12px);
    margin-right: multiply-size(4px);
  }

  span {
    font-size: multiply-size(10px);
    line-height: multiply-size(10px);
    @include mikado-black;
  }

  @import "custom/style";
}
