@import "~src/styles/functions";
@import "~src/styles/variables/core/variables";
@import "~src/styles/mixins";

app-m-virtual-keyboard {
  @include futura-heavy;
  color: $color-base;

  p {
    text-align: center;

    &.value {
      margin: 0 0 multiply-size(11px) 0;
      font-size: multiply-size(16px);

      &:before {
        content: '\00a0'
      }

      &:after {
        content: '|';
        animation: blink 0.7s infinite;

        @keyframes blink {
          from {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }
      }
    }
  }

  .row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

    button {
      text-transform: uppercase;
      margin: multiply-size(5px);

      &:disabled {
        opacity: 0;
      }

      &.mobile-square {
        font-size: multiply-size(10px);
        text-transform: none;

        &:before {
          opacity: 1;
        }
      }
    }
  }
}
