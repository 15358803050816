@import "~src/styles/functions";
@import "~src/styles/mixins";
@import "~src/styles/variables/core/variables";
@import "variables";

.container {
  width: multiply-size(270px);
}

.logo {
  background: var(--asset--ui-logo-png) center no-repeat;
  margin: multiply-size(30px) 0 multiply-size(20px) 0;
  width: multiply-size(320px);
  height: multiply-size(106px);
  background-size: contain;
}

.title, .section {
  font-size: multiply-size(20px);
  @include myriad-cond;
  font-weight: bold;
  color: $color-base-form;
}

.description {
  @include archivo-narrow;
  font-weight: bold;
  font-size: multiply-size(12px);
  color: $color-base-form;

  &.error {
    color: $color-octonary;
  }

  &.x18 {
    @include myriad-cond;
    font-weight: bold;
    font-size: multiply-size(18px);
  }
}

.controls {
  display: flex;

  .control {
    &:not(:first-child) {
      margin-left: multiply-size(5px);

      label {
        padding-left: multiply-size(10px);
      }
    }
  }
}

.control {
  margin: multiply-size(8px) 0;
  color: $color-base-form;
  display: flex;
  flex-direction: column;

  .label-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: multiply-size(3px);
  }

  label {
    font-size: multiply-size(12px);
    font-weight: bold;
    @include archivo-narrow;
    padding-left: multiply-size(19px);
  }

  input, select {
    box-sizing: border-box;
    @include archivo-narrow;
    font-weight: bold;
    font-size: multiply-size(12px);
    padding: 0 multiply-size(17px);
    width: 100%;
    height: multiply-size(44px);
    border: multiply-size(2px) solid $color-base-form;
    color: $color-base-form;
    border-radius: multiply-size(15px);
    background: transparent;
  }

  option {
    color: #4164ba;
  }

  &.control-checkbox {
    flex-direction: row;

    label {
      padding: multiply-size(10px) 0 0 multiply-size(8px);
    }

    input {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      opacity: 0;

      &:checked + .custom-checkbox-fill {
        display: block;
      }
    }

    .custom-checkbox {
      min-width: multiply-size(44px);
      max-width: multiply-size(44px);
      min-height: multiply-size(44px);
      max-height: multiply-size(44px);
      border: multiply-size(2px) solid $color-base-form;
      color: $color-base-form;
      border-radius: multiply-size(15px);
      position: relative;
      box-sizing: border-box;


      .custom-checkbox-fill {
        display: none;
        position: absolute;
        @include absolute-center;
        font-size: multiply-size(18px);
        pointer-events: none;
      }
    }
  }

  &.readonly {
    opacity: 0.3;
  }

  &.error {
    color: $color-error-form;

    input, select, .custom-checkbox {
      border-color: $color-error-form;
      color: $color-error-form;
    }
  }
}

.buttons {
  margin-top: multiply-size(20px);
  display: flex;
  align-items: center;
  flex-direction: column;

  & > * {
    margin-bottom: multiply-size(10px);
  }

  &.flex-center {
    justify-content: center;
  }
}

p {
  &.invalid-form {
    opacity: 0;
    margin-top: multiply-size(18px);
    text-align: center;
  }

  &.opacity-1 {
    opacity: 1;
  }
}

.margin-v1 {
  margin-top: multiply-size(15px) !important;
}

.margin-v2 {
  margin-top: multiply-size(25px) !important;
}

@import "custom/style";
