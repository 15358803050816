@import "~src/styles/functions";
@import "~src/styles/mixins";

.messages {
  .message {
    .content {
      .title {
        @include myriad;
        font-size: multiply-size(12px);
        line-height: multiply-size(14px);
        text-align: left;
      }
    }
  }
}
