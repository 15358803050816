@import "~src/styles/functions";
@import "~src/styles/variables/core/variables";

&.window-a {
  width: multiply-size(474px);
  height: multiply-size(276px);
  border-radius: multiply-size(10px);
  box-shadow: 0 0 0 multiply-size(2px) $color-H;
  z-index: 0;
}
