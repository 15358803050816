@import "~src/styles/functions";

app-gallery {
  @import "../../styles/style";

  background: #F6F8F9;
  padding-bottom: multiply-size(50px);
  display: block;
  min-height: 100vh;
  box-sizing: border-box;

  .images-list-container {
    .each-image {
      height: multiply-size(312px);

      @media only screen and (min-width: $media-md) {
        height: multiply-size(348px);
      }

      @media only screen and (min-width: $media-lg) {
        height: multiply-size(308px);
      }

      @media only screen and (min-width: $media-xlg) {
        height: multiply-size(348px);
      }

      @media only screen and (min-width: $media-xxlg) {
        height: multiply-size(384px);
      }

      img {
        height: multiply-size(158px);
        min-height: multiply-size(158px);
        object-fit: cover;

        @media only screen and (min-width: $media-md) {
          height: multiply-size(195px);
          min-height: multiply-size(195px);
        }

        @media only screen and (min-width: $media-lg) {
          height: multiply-size(155px);
          min-height: multiply-size(155px);
        }

        @media only screen and (min-width: $media-xlg) {
          height: multiply-size(195px);
          min-height: multiply-size(195px);
        }

        @media only screen and (min-width: $media-xxlg) {
          height: multiply-size(231px);
          min-height: multiply-size(231px);
        }
      }

      .details-border {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .each-image-title {
          margin-top: 0;
          margin-bottom: multiply-size(5px);
          height: multiply-size(32px);
        }

        .each-image-description {
          height: multiply-size(46px);
          margin-top: multiply-size(8px);
          margin-bottom: multiply-size(13px);
          @include roboto-cond;
          font-size: multiply-size(16px);
          line-height: multiply-size(24px);
        }

        .each-image-annotation {
          font-size: multiply-size(14px);
          @include roboto-cond;
          color: $color-dark;
          opacity: 0.5;

          i {
            font-size: multiply-size(16px);
            margin-right: multiply-size(2px);

            &.like-icon {
              margin-left: multiply-size(10px);
            }
          }
        }
      }
    }
  }
}

