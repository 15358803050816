perfect-scrollbar {
  display: block;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  width: 100%
}

perfect-scrollbar[hidden] {
  display: none
}

perfect-scrollbar[fxflex] {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 0;
  min-width: 0
}

perfect-scrollbar[fxflex] > .ps {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  height: auto;
  min-height: 0;
  min-width: 0;
  width: auto
}

perfect-scrollbar[fxlayout] > .ps, perfect-scrollbar[fxlayout] > .ps > .ps-content {
  -webkit-box-align: inherit;
  -webkit-box-flex: 1;
  -webkit-box-pack: inherit;
  align-content: inherit;
  align-items: inherit;
  display: flex;
  flex: 1 1 auto;
  flex-direction: inherit;
  height: 100%;
  justify-content: inherit;
  width: 100%
}

perfect-scrollbar[fxlayout=row] > .ps, perfect-scrollbar[fxlayout=row] > .ps > .ps-content {
  flex-direction: row !important
}

perfect-scrollbar[fxlayout=column] > .ps, perfect-scrollbar[fxlayout=column] > .ps > .ps-content {
  flex-direction: column !important
}

perfect-scrollbar > .ps {
  display: block;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  position: static;
  width: 100%
}

perfect-scrollbar > .ps textarea {
  -ms-overflow-style: scrollbar
}

perfect-scrollbar > .ps > .ps-overlay {
  bottom: 0;
  display: block;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0
}

perfect-scrollbar > .ps > .ps-overlay .ps-indicator-bottom, perfect-scrollbar > .ps > .ps-overlay .ps-indicator-left, perfect-scrollbar > .ps > .ps-overlay .ps-indicator-right, perfect-scrollbar > .ps > .ps-overlay .ps-indicator-top {
  opacity: 0;
  position: absolute;
  transition: opacity .3s ease-in-out
}

perfect-scrollbar > .ps > .ps-overlay .ps-indicator-bottom, perfect-scrollbar > .ps > .ps-overlay .ps-indicator-top {
  left: 0;
  min-height: 24px;
  min-width: 100%
}

perfect-scrollbar > .ps > .ps-overlay .ps-indicator-left, perfect-scrollbar > .ps > .ps-overlay .ps-indicator-right {
  min-height: 100%;
  min-width: 24px;
  top: 0
}

perfect-scrollbar > .ps > .ps-overlay .ps-indicator-top {
  top: 0
}

perfect-scrollbar > .ps > .ps-overlay .ps-indicator-left {
  left: 0
}

perfect-scrollbar > .ps > .ps-overlay .ps-indicator-right {
  right: 0
}

perfect-scrollbar > .ps > .ps-overlay .ps-indicator-bottom {
  bottom: 0
}

perfect-scrollbar > .ps.ps--active-y > .ps__rail-y {
  cursor: default;
  left: auto !important;
  right: 0 !important;
  top: 0 !important;
  transition: width .2s linear, opacity .2s linear, background-color .2s linear;
  width: 10px
}

perfect-scrollbar > .ps.ps--active-y > .ps__rail-y.ps--clicking, perfect-scrollbar > .ps.ps--active-y > .ps__rail-y:hover {
  width: 15px
}

perfect-scrollbar > .ps.ps--active-x > .ps__rail-x {
  bottom: 0 !important;
  cursor: default;
  height: 10px;
  left: 0 !important;
  top: auto !important;
  transition: height .2s linear, opacity .2s linear, background-color .2s linear
}

perfect-scrollbar > .ps.ps--active-x > .ps__rail-x.ps--clicking, perfect-scrollbar > .ps.ps--active-x > .ps__rail-x:hover {
  height: 15px
}

perfect-scrollbar > .ps.ps--active-x.ps--active-y > .ps__rail-y {
  margin: 0 0 10px
}

perfect-scrollbar > .ps.ps--active-x.ps--active-y > .ps__rail-x {
  margin: 0 10px 0 0
}

perfect-scrollbar > .ps.ps--scrolling-x > .ps__rail-x, perfect-scrollbar > .ps.ps--scrolling-y > .ps__rail-y {
  background-color: #eee;
  opacity: .9
}

perfect-scrollbar.ps-show-always > .ps.ps--active-x > .ps__rail-x, perfect-scrollbar.ps-show-always > .ps.ps--active-y > .ps__rail-y {
  opacity: .6
}

perfect-scrollbar.ps-show-active > .ps.ps--active-y > .ps-overlay:not(.ps-at-top) .ps-indicator-top {
  background: linear-gradient(180deg, hsla(0, 0%, 100%, .5) 0, hsla(0, 0%, 100%, 0));
  opacity: 1
}

perfect-scrollbar.ps-show-active > .ps.ps--active-y > .ps-overlay:not(.ps-at-bottom) .ps-indicator-bottom {
  background: linear-gradient(0deg, hsla(0, 0%, 100%, .5) 0, hsla(0, 0%, 100%, 0));
  opacity: 1
}

perfect-scrollbar.ps-show-active > .ps.ps--active-x > .ps-overlay:not(.ps-at-left) .ps-indicator-left {
  background: linear-gradient(90deg, hsla(0, 0%, 100%, .5) 0, hsla(0, 0%, 100%, 0));
  opacity: 1
}

perfect-scrollbar.ps-show-active > .ps.ps--active-x > .ps-overlay:not(.ps-at-right) .ps-indicator-right {
  background: linear-gradient(270deg, hsla(0, 0%, 100%, .5) 0, hsla(0, 0%, 100%, 0));
  opacity: 1
}

perfect-scrollbar.ps-show-active.ps-show-limits > .ps.ps--active-y > .ps-overlay.ps-at-top .ps-indicator-top {
  background: linear-gradient(180deg, hsla(0, 0%, 66.7%, .5) 0, hsla(0, 0%, 66.7%, 0))
}

perfect-scrollbar.ps-show-active.ps-show-limits > .ps.ps--active-y > .ps-overlay.ps-at-bottom .ps-indicator-bottom {
  background: linear-gradient(0deg, hsla(0, 0%, 66.7%, .5) 0, hsla(0, 0%, 66.7%, 0))
}

perfect-scrollbar.ps-show-active.ps-show-limits > .ps.ps--active-x > .ps-overlay.ps-at-left .ps-indicator-left {
  background: linear-gradient(90deg, hsla(0, 0%, 66.7%, .5) 0, hsla(0, 0%, 66.7%, 0))
}

perfect-scrollbar.ps-show-active.ps-show-limits > .ps.ps--active-x > .ps-overlay.ps-at-right .ps-indicator-right {
  background: linear-gradient(270deg, hsla(0, 0%, 66.7%, .5) 0, hsla(0, 0%, 66.7%, 0))
}

perfect-scrollbar.ps-show-active.ps-show-limits > .ps.ps--active-x > .ps-overlay.ps-at-left .ps-indicator-left.ps-indicator-show, perfect-scrollbar.ps-show-active.ps-show-limits > .ps.ps--active-x > .ps-overlay.ps-at-right .ps-indicator-right.ps-indicator-show, perfect-scrollbar.ps-show-active.ps-show-limits > .ps.ps--active-y > .ps-overlay.ps-at-bottom .ps-indicator-bottom.ps-indicator-show, perfect-scrollbar.ps-show-active.ps-show-limits > .ps.ps--active-y > .ps-overlay.ps-at-top .ps-indicator-top.ps-indicator-show {
  opacity: 1
}

.ps {
  -ms-overflow-style: none;
  -ms-touch-action: auto;
  overflow: hidden !important;
  overflow-anchor: none;
  touch-action: auto
}

.ps__rail-x {
  bottom: 0;
  height: 15px
}

.ps__rail-x, .ps__rail-y {
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  display: none;
  opacity: 0;
  position: absolute;
  transition: background-color .2s linear, opacity .2s linear
}

.ps__rail-y {
  right: 0;
  width: 15px
}

.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
  background-color: transparent;
  display: block
}

.ps--focus > .ps__rail-x, .ps--focus > .ps__rail-y, .ps--scrolling-x > .ps__rail-x, .ps--scrolling-y > .ps__rail-y, .ps:hover > .ps__rail-x, .ps:hover > .ps__rail-y {
  opacity: .6
}

.ps .ps__rail-x.ps--clicking, .ps .ps__rail-x:focus, .ps .ps__rail-x:hover, .ps .ps__rail-y.ps--clicking, .ps .ps__rail-y:focus, .ps .ps__rail-y:hover {
  background-color: #eee;
  opacity: .9
}

.ps__thumb-x {
  -webkit-transition: background-color .2s linear, height .2s ease-in-out;
  bottom: 2px;
  height: 6px;
  transition: background-color .2s linear, height .2s ease-in-out
}

.ps__thumb-x, .ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  position: absolute
}

.ps__thumb-y {
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  right: 2px;
  transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px
}

.ps__rail-x.ps--clicking .ps__thumb-x, .ps__rail-x:focus > .ps__thumb-x, .ps__rail-x:hover > .ps__thumb-x {
  background-color: #999;
  height: 11px
}

.ps__rail-y.ps--clicking .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y:hover > .ps__thumb-y {
  background-color: #999;
  width: 11px
}

@supports (-ms-overflow-style:none) {
  .ps {
    overflow: auto !important
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .ps {
    overflow: auto !important
  }
}
