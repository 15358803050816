@import "~src/styles/functions";

app-root {
  width: 100%;
  height: 100%;
  display: block;

  .dot {
    font-family: 'FAPhaser';
    position: fixed;
    left: multiply-size(-1000px);
    visibility: hidden;
  }

  .myriad-pro-phaser-bold {
    font-family: MyriadProPHASERBOLD;
    visibility: hidden;
    position: fixed;
  }
}
