@import "~src/styles/functions";
@import "~src/styles/mixins";
@import "~src/styles/variables/core/variables";

app-hud-parameters {
  @import "variables";

  .player-resources {
    display: flex;
    padding-top: multiply-size(4px);
    pointer-events: all;

    .bar {
      min-width: multiply-size(62px);
      height: multiply-size(24px);
      font-size: multiply-size(10px);
      display: flex;
      align-items: stretch;
      @include myriad;
      font-weight: 700;
      margin: multiply-size(3px);
      color: $font-color;
      position: relative;

      .icon-container {
        width: multiply-size(22px);
        background-color: $color-I;
        border-left: multiply-size(2px) solid $border;
        border-top: multiply-size(2px) solid $border;
        border-bottom: multiply-size(2px) solid $border;
        border-top-left-radius: multiply-size(6px);
        border-bottom-left-radius: multiply-size(6px);
        display: flex;
        justify-content: center;
        align-items: center;

        .ow-icon {
          height: 100%;
        }
      }

      .resource-container {
        display: flex;
        flex: 1;
        padding-left: multiply-size(3px);
        padding-right: multiply-size(2px);
        justify-content: flex-end;
        align-items: center;
        background-color: $bgc-bar;
        border-right: multiply-size(2px) solid $border;
        border-top: multiply-size(2px) solid $border;
        border-bottom: multiply-size(2px) solid $border;
        border-top-right-radius: multiply-size(6px);
        border-bottom-right-radius: multiply-size(6px);
      }

      .delta {
        position: absolute;
        right: multiply-size(-2px);
        bottom: multiply-size(-4px);
        display: flex;


        span, i {
          transform: translateX(multiply-size(2px));
        }

        span {
          margin-right: multiply-size(1px);
        }

        i {
          font-size: multiply-size(14px);
        }

        &.green {
          span, i {
            color: $color-B;
            @include text-stroke($color-O);
          }

          &.delta {
            align-items: baseline;
          }
        }

        &.red {
          span, i {
            color: $color-G;
            @include text-stroke($color-H);
          }

          &.delta {
            align-items: center;
          }
        }
      }
    }
  }

  .tooltip-container {
    font-weight: normal;
  }

  @import "custom/style";
}

