@import "~src/styles/functions";

m-ui-slider-dots {
  @import "variables";

  display: flex;
  align-items: center;
  justify-content: center;
  height: multiply-size(8px);

  .dot {
    background: $bgc-dot-base;
    border-radius: multiply-size(50px);
    transition: 0.3s;
    transform: scale(0);
    transform-origin: 50% 50%;

    &.active, &.sibling, &.sibling-far, &.no-dynamic-bullets {
      width: multiply-size(4px);
      height: multiply-size(4px);
      margin: 0 multiply-size(2px);
      box-sizing: border-box;

      &.stroke {
        height: multiply-size(6px);
        margin: 0 multiply-size(1px);
        width: multiply-size(6px);
        border: multiply-size(1px) solid $stroke-dot;
      }
    }

    &.sibling-far {
      transform: scale(0.4);
    }

    &.sibling, &.no-dynamic-bullets {
      transform: scale(0.7);
    }

    &.active {
      background: $bgc-dot-active;
      transform: scale(1);
    }
  }

  @import "custom/style";
}
