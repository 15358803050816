@import "~src/styles/functions";

app-registration-a {
  @import "../../../styles/styles";
  display: flex;
  flex-direction: column;
  align-items: center;

  .description {
    display: block;
    margin: multiply-size(5px) 0;

    p {
      margin: 0;
    }
  }

  .buttons {
    flex-direction: row;
    justify-content: space-between;
  }
}
