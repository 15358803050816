@import "~src/styles/functions";
@import "~src/styles/mixins";
@import "~src/styles/variables/core/variables";

app-play-game {
  height: 100%;
  overflow: hidden;
  display: block;

  .window-router-outlet-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;

    &.center {
      justify-content: center;
      align-items: center;
    }

    .window-router-outlet {
      pointer-events: all;
    }
  }

  .loading {
    position: fixed;
    left: 0;
    top: 0;
    background-size: cover;
    width: 100%;
    height: 100%;

    .preloader {
      position: absolute;
      left: multiply-size(20px, '50% - ');
      top: multiply-size(20px, '50% - ');
      font-size: multiply-size(55px);
      color: $color-secondary;
    }

    &.hidden {
      visibility: hidden;
      opacity: 0;

      //transform: scale(2);
      transition: all 0.6s ease-in;
    }
  }
}

