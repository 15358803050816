@import "~src/styles/functions";
@import "~src/styles/mixins";

app-hud-player-level {
  .hud-player-level {
    width: multiply-size(36px);
    height: multiply-size(36px);
    border-radius: multiply-size(50px);
    border: multiply-size(2px) solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    position: relative;
    margin: multiply-size(3px) multiply-size(10px) 0 multiply-size(3px);
    cursor: pointer;

    .level {
      position: absolute;
      top: 0;
      left: 0;
      width: multiply-size(18px);
      height: multiply-size(18px);
      background: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      @include mikado;
      font-size: multiply-size(10px);
      color: #ffffff;
      border-radius: multiply-size(50px);
      transform: translate(-38%, -38%);
      font-weight: bold;
      z-index: 1;
    }

    .inner-circle {
      width: multiply-size(25px);
      height: multiply-size(25px);
      z-index: 2;
      background-size: contain;
      transform: translate(multiply-size(-0.5px), multiply-size(0.5px));
    }
  }

  #circle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transform: rotate(90deg);
    width: multiply-size(36px);
    height: multiply-size(36px);

    circle {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 1s linear;
      stroke: #73B100;
    }
  }
}
