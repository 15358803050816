@import "~src/styles/functions";
@import "~src/styles/mixins";

app-hud-parameters-custom {
  @import "~src/styles/variables/core/variables";

  $font-color: $color-I;
  $bgc-bar: #358F42;
  $border: #000000;

  .icon-container {
    border-right: multiply-size(2px) solid $border;
  }


  .player-resources {
    padding-top: 0;
    display: flex;
    pointer-events: all;

    .bar {
      min-width: multiply-size(62px);
      height: multiply-size(24px);
      font-size: multiply-size(10px);
      display: flex;
      align-items: stretch;
      @include mikado;
      font-weight: 700;
      margin: multiply-size(3px);
      color: $font-color;
      position: relative;

      .icon-container {
        width: multiply-size(22px);
        background-color: $color-I;
        border-left: multiply-size(2px) solid $border;
        border-top: multiply-size(2px) solid $border;
        border-bottom: multiply-size(2px) solid $border;
        border-top-left-radius: multiply-size(6px);
        border-bottom-left-radius: multiply-size(6px);
        display: flex;
        justify-content: center;
        align-items: center;

        .ow-icon {
          height: 100%;
        }
      }

      .resource-container {
        display: flex;
        flex: 1;
        padding-left: multiply-size(3px);
        padding-right: multiply-size(3px);
        justify-content: flex-end;
        align-items: center;
        background-color: $bgc-bar;
        border-right: multiply-size(2px) solid $border;
        border-top: multiply-size(2px) solid $border;
        border-bottom: multiply-size(2px) solid $border;
        border-top-right-radius: multiply-size(6px);
        border-bottom-right-radius: multiply-size(6px);
      }

      .delta {
        position: absolute;
        right: multiply-size(-2px);
        bottom: multiply-size(-4px);
        display: flex;
        align-items: center !important;
        justify-content: flex-end;
        flex-direction: row-reverse;
        z-index: 1;

        &.green, &.red {
          left: multiply-size(-7px) !important;
          right: unset;

          i {
            width: multiply-size(20px);
            height: multiply-size(20px);
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: multiply-size(-2px);
          }

          span {
            @include text-stroke($color-H);
          }
        }

        &.green {
          bottom: multiply-size(-11px) !important;

          span {
            color: $color-B;
          }

          &.delta {
            align-items: baseline;
          }

          i {
            background-image: var(--asset--ui-arow-green-png);
          }
        }

        &.red {
          bottom: multiply-size(-10px) !important;

          span {
            color: $color-G;
          }

          &.delta {
            align-items: center;
          }

          i {
            background-image: var(--asset--ui-arow-red-png);
          }
        }
      }

      &.co2 {
        .resource-container {
          &.green {
            background-color: #1DBF35;
          }

          &.red {
            background-color: #DB4300;
          }
        }
      }
    }
  }

  .tooltip-container {
    font-weight: normal;
  }

  .fa-arrow-alt-up::before {
    display: none !important;
  }

  .fa-arrow-alt-down::before {
    display: none !important;
  }
}

