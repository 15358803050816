app-custom-registration-instant {
  @import "../../../../../basic/styles/styles";
  display: flex;
  flex-direction: column;
  align-items: center;

  .description {
    display: block;
    margin: multiply-size(5px) 0;

    p {
      margin: 0;
    }
  }

  .title {
    text-align: center;
  }
  
  .buttons {
    flex-direction: row;
    gap: multiply-size(5px);
    justify-content: space-between;
  }

  .alert {
    @include archivo-narrow;
    font-size: multiply-size(12px);
    text-align: center;
    margin-top: multiply-size(6px);
    color: #FF0000;
    font-weight: bold;
  }

  .error {
    color: #FF0000 !important;
  }

  .text-error {
    text-align: center;
    font-size: multiply-size(12px);
    padding: 8px;
    @include archivo-narrow;
  }
  .text-hint{
    @include archivo-narrow;
    text-align: center;
    font-size: multiply-size(12px);
    padding: 8px;
    text-align: center;
    margin-top: multiply-size(6px);
    font-weight: bold;
  }
}
