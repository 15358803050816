@import "../../../../../../../../../styles/functions";
@import "../../../../../../../../../styles/variables/core/variables";
@import "../../../../../../../../../styles/mixins";
@import "../../../../../../../../../styles/dialogs/core/mixins";
@import "../../../../../../../../../styles/dialogs/core/variables";

app-mission-group-list {
  @import "variables";

  .exit.normal {
    top: multiply-size(-12px);
    right: multiply-size(-24px);
    position: absolute;
  }

  .navigation-bar {
    position: absolute;
    @include absolute-center-vertical;

    &.prev {
      left: multiply-size(-40px);
    }

    &.next {
      right: multiply-size(-40px);
    }
  }

  .global-dialog {
    width: multiply-size(510px);
    height: multiply-size(302px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .top-info {
    @include absolute-center-horizontal;
    @include text-stroke;
    top: 0;
    color: $top-info-color;
    text-align: center;
    font-weight: bold;

    .title {
      @include myriad;
      font-size: multiply-size(14px);
      margin-bottom: multiply-size(5px);
      text-transform: uppercase;
    }

    .sub-title {
      @include archivo-narrow;
      font-size: multiply-size(10px);
      margin: 0;
    }
  }

  .bottom-info {
    @include absolute-center-horizontal;
    @include myriad;
    font-weight: bold;
    line-height: 1;
    text-align: left;
    font-size: multiply-size(10px);
    color: $bottom-info-color;
    bottom: multiply-size(2px);
    background: $bottom-info-background;
    height: multiply-size(32px);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: multiply-size(6px);
    width: multiply-size(252px);
    padding-left: multiply-size(17px);
    letter-spacing: multiply-size(-0.1px);

    .icon {
      background: $bottom-info-background;
      position: absolute;
      border: multiply-size(2px) solid $bottom-info-color;
      border-radius: multiply-size(50px);
      display: flex;
      justify-content: center;
      align-items: center;
      color: $bottom-info-color;
      font-size: multiply-size(16px);
      width: multiply-size(30px);
      height: multiply-size(30px);
      left: multiply-size(-20px);
    }
  }

  .collections {
    display: flex;
    justify-content: center;

    .window-b {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      position: relative;
      padding: multiply-size(16px) multiply-size(10px) multiply-size(6px) multiply-size(10px);
      margin-top: multiply-size(20px);

      .icon {
        width: multiply-size(38px);
        height: multiply-size(38px);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }

      .name {
        @include myriad;
        @include text-stroke;
        font-size: multiply-size(12px);
        font-weight: bold;
        color: $collections-name-color;
        text-align: center;
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .score-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: multiply-size(46px);
        height: multiply-size(26px);

        img {
          width: multiply-size(25px);
          height: multiply-size(25px);
          object-fit: contain;
          position: absolute;
          top: 0;
          left: multiply-size(-4px);
          z-index: 3;

        }

        .score-container {
          position: relative;
          width: 100%;
          height: multiply-size(18px);
          box-shadow: inset 0 0 0 multiply-size(2px) $collections-score-container-border;
          overflow: hidden;
          border-radius: multiply-size(5px);
          background-color: $collections-score-container-background;

          .score {
            @include myriad;
            @include text-stroke;
            position: absolute;
            top: multiply-size(4px);
            right: multiply-size(5px);
            font-size: multiply-size(9px);
            font-weight: bold;
            color: $collections-score-container-score-color;
            letter-spacing: multiply-size(1px);
          }
        }
      }

      .progressbar-container {
        width: multiply-size(69px);
        height: multiply-size(26px);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          &.check {
            position: absolute;
            right: multiply-size(6px);
            width: multiply-size(20px);
            height: multiply-size(20px);
            object-fit: contain;
            z-index: 4;
          }
        }

        .box {
          position: relative;
          overflow: hidden;
          border-radius: multiply-size(5px);
          width: 100%;
          height: multiply-size(18px);
          box-sizing: border-box;
          background: $progressbar-background;

          .border {
            box-shadow: inset 0 0 0 multiply-size(2px) $progressbar-border;
            width: 100%;
            height: 100%;
            z-index: 2;
            position: absolute;
            border-radius: multiply-size(5px);
          }

          .fill {
            transition: width 0.3s;
            position: absolute;
            background: linear-gradient(to bottom, #94D312 50%, #73A60D 50%);
            left: multiply-size(18px);
            top: 0;
            bottom: multiply-size(8px);
            right: multiply-size(2px);
            max-width: multiply-size(57px);
            width: 0;
            height: 100%;
            border-right: multiply-size(1px) solid $progressbar-border-fill-border;
            z-index: 1;
            border-radius: multiply-size(4px);
          }
        }

        .progress-icon {
          position: absolute;
          top: 0;
          left: multiply-size(-2px);
          z-index: 3;

          img {
            width: multiply-size(25px);
            height: multiply-size(25px);
            object-fit: contain;
          }
        }
      }
    }
  }

  swiper {
    margin-bottom: multiply-size(6px);
  }

  @import "custom/style";
}
