@import "~src/styles/functions";

app-hud-menu-more {
  m-ui-close-button {
    button.exit.normal {
      position: absolute;
      top: multiply-size(-26px);
      right: multiply-size(-32px);

      i {
        font-size: multiply-size(18px) !important;
        @include text-stroke();
        color: #ffffff !important;
      }
    }
  }

  .global-dialog {
    width: multiply-size(340px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  app-custom-hud-menu {
    width: 100%;

    .main-buttons-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .main-button {
        height: multiply-size(70px);
        margin: multiply-size(10px) multiply-size(13px);
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;

        ow-object-svg {
          height: auto;
        }

        .button-name {
          margin-top: multiply-size(4px);
          @include text-stroke;
          @include archivo-narrow;
          font-size: multiply-size(10px);
          font-weight: bold;
        }
      }
    }
  }
}
