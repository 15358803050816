& {
  .is-loading & .container.loading-opacity {
    opacity: 0.8;
  }

  .is-loading & loading .loading-content.small .relative {
    font-size: multiply-size(45px);
    color: #ffffff;
    @include text-stroke(#000000);
  }

  &:after {
    content: '';
    position: fixed;
    background: var(--asset--ui-background-png) no-repeat center center fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
}

.container {
  background-color: rgba(250, 250, 250, 0.9);
  border: multiply-size(2px) solid #000;
  max-width: multiply-size(362px);
  width: 100%;
  border-radius: multiply-size(8px);
  padding: multiply-size(15px) multiply-size(17px) multiply-size(13px) multiply-size(17px);
  box-sizing: border-box;

  input[type=text], input[type=password] {
    background: #ffffff;
  }
}

.base {
  width: 100%;
  height: multiply-size(40px);
  font-size: multiply-size(16px);
  text-transform: uppercase;
}

.description {
  font-size: multiply-size(16px);
}

.buttons {
  margin-top: multiply-size(40px);
}

.control {
  input {
    border-radius: multiply-size(8px) !important;
  }
}

.logo {
  width: 372px;
  height: 200px;
}

.client-logo {
  height: multiply-size(80px);
  margin: calc(20px * var(--multiply)) auto 0;

  img {
    object-fit: cover;
    height: 100%;
  }
}

.client-title {
  margin-top: multiply-size(10px);
  @include archivo-narrow;
  font-size: calc(18px * var(--multiply));
  font-family: "Archivo Narrow", sans-serif;
  font-weight: bold;
  color: #ffffff;
  border-color: #000000;
  min-height: 21px;
  @include text-stroke;
}
