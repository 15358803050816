@import "~src/styles/functions";
@import "~src/styles/mixins";

app-message-details-level-up {
  @import "../../styles/style";
  @import "variables";

  m-ui-separator {
    .separator {
      margin-top: multiply-size(6px);
    }
  }

  .window-a {
    padding-bottom: multiply-size(5px);
    min-height: multiply-size(276px);
    height: auto;
    position: relative;
  }

  .level-up-container {
    margin-top: multiply-size(-10px);

    .level-up-top-wrapper {
      display: flex;

      .level-up-ribbon {
        margin-right: multiply-size(20px);
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        .level {
          background-color: $color-K;
          width: multiply-size(40px);
          height: multiply-size(40px);
          border-radius: multiply-size(50px);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: multiply-size(17px);
          color: $color-I;
          @include mikado-black;
          @include text-stroke;
          position: relative;

          i {
            color: $color-B;
            @include text-stroke($color-O);
            position: absolute;
            top: multiply-size(1px);
            left: multiply-size(-2px);
            font-size: multiply-size(18px);
          }
        }
      }

      .inner-html-description {
        margin: multiply-size(10px) 0;
        @include myriad;
        font-weight: bold;
        font-size: multiply-size(12px);
        text-align: left;
        width: 100%;
      }
    }

    swiper {
      .page {
        display: flex;
        justify-content: center;
        align-items: center;

        m-building-thumbnail {
          margin: 0 multiply-size(4px);

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        .product-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 multiply-size(8px);
          align-items: center;

          span {
            margin-top: multiply-size(2px);
            font-size: multiply-size(9px);
            @include archivo-narrow;
            font-weight: bold;
            pointer-events: none;
          }
        }

        m-ui-product {
          margin: 0 multiply-size(4px);
        }

        .building-thumbnail-container {
          width: multiply-size(130px);
          position: relative;
          text-align: center;

          img {
            width: multiply-size(40px);
            height: multiply-size(40px);
            object-fit: contain;
          }

          .level {
            text-align: center;
            font-size: multiply-size(9px);
            @include archivo-narrow;
            font-weight: bold;
            pointer-events: none;
            margin: 0;
          }
        }
      }
    }

    m-ui-slider-dots {
      margin-top: multiply-size(10px);
    }

    .arrows-slider {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: multiply-size(-5px);
      z-index: 10;

      i {
        &.left, &.right {
          cursor: pointer;
          padding: multiply-size(5px);
          font-size: multiply-size(14px);

          &.hide {
            visibility: hidden;
          }
        }

        &.left {
          transform: translate(multiply-size(-5px), multiply-size(5px));
        }

        &.right {
          transform: translate(multiply-size(5px), multiply-size(5px));
        }
      }
    }
  }

  .trash-icon {
    right: multiply-size(8px);
    bottom: multiply-size(4px);
  }

  @import "custom/style";
}
