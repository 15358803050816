@import "~src/styles/functions";
@import "../../../../../../../../styles/mixins";
@import "../../../../../../../../styles/variables/core/variables";

hud-star-missions {
  $arrow-size: multiply-size(2px);

  @include archivo-narrow;
  height: 100%;
  box-sizing: border-box;
  padding: multiply-size(28px) multiply-size(16px) multiply-size(22px);
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 0;

  .stars {
    display: flex;
    margin-bottom: multiply-size(12px);

    .star {
      margin-right: multiply-size(10px);
      height: multiply-size(40px);
      width: multiply-size(40px);
      background-image: var(--asset--ui-leaf_empty-png);
      background-size: contain;
      background-repeat: no-repeat;

      &.star-full {
        @for $i from 1 through 9 {
          &.leaf-#{$i} {
            background-image: var(--asset--ui-leaf_full_#{$i}-png);
          }
        }
      }

      &.star-half {
        @for $i from 1 through 9 {
          &.leaf-#{$i} {
            background-image: var(--asset--ui-leaf_half_#{$i}-png);
          }
        }
      }
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: multiply-size(9px);
    margin-bottom: multiply-size(6px);

    span {
      @include mikado();
      font-size: multiply-size(13px);
      font-weight: bold;
    }
  }

  .description {
    font-size: multiply-size(9px);
    width: 100%;
    padding: 0 multiply-size(28px);
    text-align: center;
    margin-bottom: multiply-size(12px);
    box-sizing: border-box;
  }

  .empty-space {
    height: multiply-size(25px);
  }

  .missions {
    display: flex;
    width: 100%;
    background-color: #EEEBE4;
    padding: multiply-size(8px) multiply-size(8px) multiply-size(4px) multiply-size(10px);
    align-items: center;
    justify-content: center;
    height: multiply-size(92px);
    max-height: multiply-size(92px);
    box-sizing: border-box;
    z-index: 11;

    .ps__rail-y {
      opacity: 1;
    }

    .missions-info {
      font-size: multiply-size(9px);
      width: multiply-size(146px);
      min-width: multiply-size(146px);
      height: multiply-size(72px);
      max-height: multiply-size(72px);
      box-sizing: border-box;
    }

    .missions-stages {
      flex: 1;
      margin-left: multiply-size(10px);

      .stage {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: multiply-size(4px);
        font-size: multiply-size(9px);

        .stage-box {
          display: flex;
          align-items: center;

          &.stage-box-active {
            color: #62B500;

            .box {
              &:after {
                content: '';
                background-image: var(--asset--ui-icon-check-png);
                background-repeat: no-repeat;
                background-size: contain;
                width: multiply-size(16px);
                height: multiply-size(16px);
                position: absolute;
              }
            }
          }

          .box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: multiply-size(16px);
            height: multiply-size(16px);
            border: solid multiply-size(2px) $color-K;
            border-radius: multiply-size(4px);
            font-size: multiply-size(7px);
            margin-right: multiply-size(7px);
            background-color: #E8F3FF;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  .mt-auto {
    margin-top: auto;
  }

  .no-item {
    font-size: multiply-size(20px);
    font-weight: bold;
    margin-top: multiply-size(85px);
  }

  .success {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .desc {
      font-size: multiply-size(12px);
      @include archivo-narrow();
      margin-top: multiply-size(2px);
    }

    p {
      font-size: multiply-size(20px);
      font-weight: bold;
      @include mikado();
      line-height: 27px;
      margin-bottom: multiply-size(18px);
      margin-top: multiply-size(40px);
    }
  }
}
