@import "~src/styles/variables/core/variables";
@import "../variables";

$colors: (
  $font: #ffffff,
  $background: $color-B,
  $box-shadow: #ffffff,
);

&.secondary {
  @include generate-schema-dialog-color($colors)
}
