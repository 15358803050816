@import "~src/styles/functions";

auth-player-choose {
  @import "../../styles/styles";
  display: flex;
  flex-direction: column;
  align-items: center;

  .control {
    &.control-checkbox {
      margin-bottom: multiply-size(20px);
    }
  }

  .buttons {
    margin-top: multiply-size(35px);

    button {
      &.base {
        width: 100%;
      }
    }
  }

  loading {
    .loading-content {
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
    }
  }
}
