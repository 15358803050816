@import "~src/styles/functions";
@import "~src/styles/variables/core/variables";
@import "~src/styles/mixins";

app-custom-warehouse-details {
  @import "../../../core/dialogs/warehouse-details/variables";

  .window-a {
    padding: multiply-size(22px) multiply-size(33px) multiply-size(12px) multiply-size(33px);
  }

  .product-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;

    .product-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 60%;
      justify-content: space-evenly;

      .product-in-glow-box {
        width: multiply-size(58px);
        height: multiply-size(55px);
        margin: 0 multiply-size(5px) multiply-size(6px) multiply-size(5px);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .product-icon {
          width: multiply-size(38px);
          height: multiply-size(38px);
          object-fit: contain;
        }

        .product-balance {
          position: absolute;
          bottom: multiply-size(-8px);
          font-size: multiply-size(10px);
          @include myriad-cond;
          font-weight: bold;
          left: 0;
          text-align: center;
          width: 100%;
        }

        .clip-path {
          position: absolute;
          top: multiply-size(-1px);
          left: multiply-size(-2px);
          height: 100%;
          width: 100%;
          border: multiply-size(2px) solid $clip-path-color;
          border-radius: multiply-size(10px);
          clip-path: polygon(-3% 107.06%, -3% -2%, 106.72% -3%, 104.03% 109.89%, 76.01% 109.32%, 75.74% 98.35%, 86.16% 96.93%, 92% 11%, 7% 11%, 12.85% 96.65%, 24.42% 98.06%, 26.3% 108.48%);
        }
      }

      .description {
        margin-top: multiply-size(6px);
        font-size: multiply-size(12px);
        text-align: center;
        @include myriad-cond;
        font-weight: bold;
      }
    }
  }

  button.primary.base {
    margin: multiply-size(7px) 0;
  }
}
