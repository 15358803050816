@import "~src/styles/functions";
@import "~src/styles/variables/core/variables";
@import "~src/styles/mixins";

app-splash-screen {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 800;
  pointer-events: none;

  @media screen and (max-width: 1280px) {
    &.active .splash-bg {
      background-size: clamp(200px, 75svh, 500px);
      background-position: center 30%;
    }
  }

  .splash-bg {
    width: 100%;
    height: 100%;
    background: #ffffff var(--asset--ui-logo-png) center no-repeat;
    opacity: 0;
    transform: scale(1.5);
    transition: 0.3s;
  }

  &.active {
    pointer-events: all;
    background: #ffffff;

    .splash-bg {
      opacity: 1;
      transform: scale(1);
    }
  }

  .percentage {
    font-size: multiply-size(30px);
    color: $color-base;
    @include futura-heavy;
    position: absolute;
    @include absolute-center-horizontal;
    bottom: multiply-size(40px);

    @media (max-height: 600px) {
      bottom: 10svh;
      font-size: multiply-size(20px);
    }
    @media (max-height: 300px) {
      bottom: 5svh;
      font-size: multiply-size(15px);
    }
  }

  loading {
    .loading-content {
      .relative {
        i {
          position: absolute !important;
          bottom: multiply-size(90px);
          @media (max-height: 600px) {
            bottom: 20svh;
          }
          @media (max-height: 300px) {
            bottom: 15svh;
          }
        }
      }
    }
  }

  @import "custom/style";
}
