@import "~src/styles/functions";
@import "~src/styles/variables/core/variables";
@import "~src/styles/mixins";

app-hud-menu {
  @import "variables";

  .wipe {
    width: multiply-size(44px);
    height: multiply-size(44px);
    cursor: pointer;
    position: relative;
    background-color: $bgc-wipe;
    border: multiply-size(2px) solid $border;
    border-radius: multiply-size(100px);
    font-size: multiply-size(16px);
    margin-bottom: multiply-size(2px);
    @include notificationBounce;

    svg {
      width: multiply-size(40px);
      height: multiply-size(40px);
    }
  }

  .player-info-container {
    height: multiply-size(24px);
    background-color: $bgc-wipe;
    border: multiply-size(2px) solid $border;
    border-left: none;
    border-radius: multiply-size(6px);
    font-size: multiply-size(10px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    @include myriad;
    font-weight: 700;
    margin: multiply-size(8px) 0;
    color: $font-color;
    padding-right: multiply-size(6px);
    @include text-stroke($border);

    button {
      margin-right: multiply-size(6px);
    }
  }

  @import "custom/style";
}
