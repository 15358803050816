@mixin myriad($_important:"") {
  font-family: 'Mikado', sans-serif unquote($_important)
}

@mixin myriad-cond($_important:"") {
  font-family: 'Mikado', sans-serif unquote($_important)
}

@mixin myriad-cond-core($_important:"") {
  font-family: 'MyriadPro-Cond', sans-serif unquote($_important)
}

@mixin myriad-core($_important:"") {
  font-family: 'MyriadPro', sans-serif unquote($_important)
}
