@import "~src/styles/functions";
@import "~src/styles/variables/core/variables";
@import "../../mixins";
@import "mixins";


button {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  color: $color-I;
  font-size: multiply-size(14px);
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: none;

  i {
    @include text-stroke;
  }

  //  Base button
  @import "layout/button-base";

  //  Square button
  @import "layout/button-square";

  //  Short button
  @import "layout/button-short";

  // Long button
  @import "layout/button-long";

  // CUSTOM LAYOUT
  @import "../custom/layout/button-layouts";

  //  Button primary color
  @import "colors/button-primary";

  //  Button secondary color
  @import "colors/button-secondary";

  //  Button tertiary color
  @import "colors/button-tertiary";

  // CUSTOM COLOR
  @import "../custom/colors/button-colors";

  //  Disabled buttons
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.icon-left-side {
    i {
      margin-right: multiply-size(4px);
    }
  }

  &.icon-right-side {
    i {
      margin-left: multiply-size(4px);
    }
  }
}

@import "../custom/base";
