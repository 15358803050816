app-knowledge-pill {
  width: 70vw;
  max-width: multiply-size(640px);
  display: block;

  .container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;

    .dev-info {
      background: rgba(251, 255, 0, 0.9);
      padding: multiply-size(15px);
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      z-index: 2;
      white-space: break-spaces;
      overflow: auto;
      cursor: pointer;
      height: 10%;
      width: 20%;

      &.details-show {
        height: initial;
        width: initial;
      }
    }

    .pill {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      width: 100%;
      height: 100%;

      .prize-container {
        display: flex;
        position: absolute;
        top: multiply-size(5px);
        right: multiply-size(20px);
        align-items: center;
        @include text-stroke;
        color: #ffffff;
        font-size: multiply-size(22px);
        font-weight: bold;
        column-gap: multiply-size(7px);
        max-height:multiply-size(15px);

        

        .prize-container-separator{
          margin-block-end: 0em;
          margin-block-start: 0em;
          font-size: multiply-size(22px);
        }

        @media only screen and (max-width: 1200px){
          right: multiply-size(18px);
          i {
            max-height:multiply-size(18px);
          }
          .prize-container-separator{
            margin-block-end: 0px;
            font-size:multiply-size(15px);
          }
        }
        @media only screen and (max-width: 1000px){
          right: multiply-size(15px);
          i {
            max-height:multiply-size(15px);
          }
          .prize-container-separator{
            font-size:multiply-size(12px);
          }
        }
      }

      &.active {
        opacity: 1;
        pointer-events: all;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .box-clicked {
        opacity: 0.4;
        cursor: pointer;
        position: absolute;
      }
    }

    &.dev {
      .pill {
        .box-clicked {
          background: rgba(255, 0, 0, 0.3);
        }
      }
    }
  }

  .tooltip-container {
    font-size: multiply-size(7px);
    color: black;
    max-width: multiply-size(200px);
  }
}
