@import "../../core/variables";
@import "../../core/mixins";

$colors: (
  $font-stroke: #000000,
  $border: #000000,
  $background: (
    $normal: #3D81F2,
    $active: #2057B4,
  ),
  $box-shadow: (
    $normal: #00289B,
    $active: #00248B,
  ),
  $font: (
    $normal: #ffffff,
    $active: #B9B9B9,
  )
);

&.secondary {
  @include generate-schema-button-color($colors)
}
