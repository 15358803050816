@import "~src/styles/functions";

app-custom-login {
  @import "../../../../basic/styles/styles";
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    margin-bottom: 0;
  }

  .description {
    display: block;
    margin: multiply-size(5px) 0;

    p {
      margin: 0;
    }
  }

  .control {
    &.control-checkbox {
      &.remember-me-container {
        margin-top: multiply-size(20px);
        align-items: center;

        label {
          padding: 0;
          padding-left: multiply-size(8px);
        }
      }
    }
  }
  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    color: black;
    .text{
      font-weight: bold;
      background-color: #f0f0f0; /* Bright background color */
      border: 3px solid #000; /* Black border with 3px width */
      border-radius: 10px; /* Rounded corners for the border */
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
      padding: 3px multiply-size(10px);
      font-family: 'Montserrat', sans-serif;
      font-size: 0.7rem;
      margin-bottom: multiply-size(10px);
      a{
      text-shadow: none;
      color: black;
      }
    }
    @media only screen and (max-height: 700px) {
      position: static;
    }
  }
}
