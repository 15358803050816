@import "~src/styles/functions";
&.square {
  border: multiply-size(2px) solid;

  &.small {
    width: multiply-size(26px);
    height: multiply-size(26px);
    border-radius: multiply-size(7px);
  }

  &.normal {
    width: multiply-size(32px);
    height: multiply-size(32px);
    border-radius: multiply-size(9px);
  }
}
