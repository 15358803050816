@import "~src/styles/functions";

app-socket-connection-error {
  @import "../../styles/styles";
  display: flex;
  flex-direction: column;
  align-items: center;

  .description {
    display: block;
    margin: multiply-size(5px) 0;

    p {
      margin: 0;
    }
  }

  .mt {
    margin-top: 15px
  }

  @import "custom/styles";
}
