custom-auth-reset-password {
  @import "../../../../basic/styles/styles";
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    margin-bottom: 0;
  }
}
