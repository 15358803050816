@import "../../core/variables";
@import "../../core/mixins";

$colors: (
  $font-stroke: #000000,
  $border: #000000,
  $background: (
    $normal: #74C100,
    $active: #5B9800,
  ),
  $box-shadow: (
    $normal: #3E7103,
    $active: #3E7103,
  ),
  $font: (
    $normal: #ffffff,
    $active: #B9B9B9,
  )
);

&.primary {
  @include generate-schema-button-color($colors)
}
