@import "~src/styles/functions";
@import "~src/styles/mixins";
@import "~src/styles/variables/core/variables";

app-neighbourhood-custom {
  .window-a {
    padding: multiply-size(20px) multiply-size(36px) multiply-size(20px) multiply-size(36px);
    display: flex;
    flex-direction: column;
  }

  .parameters-container {
    flex-grow: 1;

    table {
      border-spacing: 10px;
      width: multiply-size(300px);
      margin: 0 auto;

      tr {
        th, td {
          &:nth-child(3), &:nth-child(4) {
            text-align: center;
          }
        }

        th {
          font-size: multiply-size(10px);
          @include archivo-narrow;
          font-weight: normal;
        }

        td {
          font-size: multiply-size(13px);
          @include mikado;
          font-weight: bold;

          .icon {
            width: multiply-size(38px);
            height: multiply-size(38px);
          }

          .count-buildings {
            color: #E60000;

            &.max {
              color: #54B500;
            }
          }
        }
      }
    }
  }

  .bottom-description {
    padding: 0 multiply-size(30px);
    margin-top: multiply-size(20px);
    @include archivo-narrow;
    font-size: multiply-size(10px);
    text-align: center;
  }


}
