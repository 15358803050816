@import "~src/styles/functions";
@import "~src/styles/mixins";
@import "~src/styles/variables/core/variables";

app-core-currency-exchange {
  @import "variables";

  .window-a {
    padding: multiply-size(30px) multiply-size(38px) multiply-size(22px) multiply-size(38px);
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;

    .exchange {
      display: flex;
      justify-content: center;
      margin: multiply-size(10px) auto multiply-size(30px);
      height: multiply-size(80px);
      width: 100%;
      align-items: center;

      .exchange-from,
      .exchange-to {
        width: multiply-size(60px);
        height: multiply-size(60px);
        border: multiply-size(2px) solid $border-color;
        border-radius: multiply-size(4px);
        background-color: $bgc-currency-choose-box;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .exchange-from {
        cursor: pointer;
        margin-right: multiply-size(15px);
      }

      .exchange-to {
        margin-left: multiply-size(15px);
      }
    }

    .form-container {
      display: flex;
      width: 90%;
      justify-content: space-evenly;

      .control {
        margin: multiply-size(8px) 0;
        color: inherit;
        display: flex;
        flex-direction: column;
        font-size: multiply-size(12px);
        font-weight: bold;
        @include myriad;

        label {
          padding-left: multiply-size(8px);
        }

        &.control-checkbox {
          flex-direction: row;
          align-items: center;
          justify-content: center;

          input {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
            opacity: 0;
            cursor: pointer;

            &:checked + .custom-checkbox-fill {
              display: block;
            }
          }

          .custom-checkbox {
            min-width: multiply-size(22px);
            max-width: multiply-size(22px);
            min-height: multiply-size(22px);
            max-height: multiply-size(22px);
            border: multiply-size(2px) solid #000000;
            color: #000000;
            border-radius: multiply-size(5px);
            position: relative;
            box-sizing: border-box;
            background: #ffffff;

            .custom-checkbox-fill {
              display: none;
              position: absolute;
              @include absolute-center;
              font-size: multiply-size(16px);
              pointer-events: none;
            }
          }
        }
      }
    }

    form {
      width: unset;
    }

    input {
      background: $color-I;
    }

    input,
    .only-border {
      border: multiply-size(2px) solid $border-color;
      padding: 0 multiply-size(14px);
      height: multiply-size(32px);
      width: multiply-size(132px);
      font-size: multiply-size(12px);
      box-sizing: border-box;
      @include myriad;
      font-weight: 700;
      text-align: center;
      color: $color-K;
      border-radius: multiply-size(6px);
      margin: 0 multiply-size(5px);
    }

    .summary {
      @include text-stroke;
      @include mikado;
      margin-top: multiply-size(24px);
      word-spacing: multiply-size(2px);
      font-size: multiply-size(12px);
      color: $color-I;
    }
  }

  .tooltip-exchange-from,
  .tooltip-exchange-to {
    display: flex;
    background: $bgc-tooltip;
    position: absolute;
    padding: multiply-size(4px) multiply-size(6px);
    border: multiply-size(1px) solid $border-color;
    border-radius: multiply-size(4px);
    z-index: 1;

    m-ui-currency {
      margin: multiply-size(6px);
      cursor: pointer;
    }
  }

  .tooltip-exchange-from {
    top: 0;
    margin-top: multiply-size(-7px);
    transform: translateY(-100%);

    &:after {
      bottom: multiply-size(-5px);
      border-bottom-left-radius: multiply-size(4px);
      border-bottom-right-radius: multiply-size(4px);
      border-top: none !important;
    }
  }

  .tooltip-exchange-to {
    bottom: 0;
    margin-bottom: multiply-size(-7px);
    transform: translateY(100%);

    &:after {
      top: multiply-size(-5px);
      border-top-left-radius: multiply-size(4px);
      border-top-right-radius: multiply-size(4px);
      border-bottom: none !important;
    }
  }

  .tooltip-exchange-from,
  .tooltip-exchange-to {
    &:after {
      content: '';
      background-color: $bgc-tooltip;
      width: multiply-size(7px);
      height: multiply-size(4px);
      position: absolute;
      border: multiply-size(1px) solid $border-color;
      transform: translate(50%);
      right: 50%;
    }
  }

  .spread {
    @include absolute-center;
    @include mikado;
    @include text-stroke;
    color: $color-I;
    font-size: multiply-size(16px);
    bottom: multiply-size(-8px);
    top: unset;
  }

  .cursor {
    cursor: pointer;
  }

  .disabled {
    opacity: 0.5;
  }

  @import "custom/style";
}
