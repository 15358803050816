.player-resources {
  padding-top: 0;

  .bar {
    &.co2 {
      .resource-container {
        background-color: #DB4300;
      }

      .delta {
        &.red {
          span, i {
            color: $color-B;
            @include text-stroke($color-O);
          }
        }

        &.green {
          span, i {
            color: $color-G;
            @include text-stroke($color-H);
          }
        }
      }
    }

    .resource-container {
      padding-right: multiply-size(3px);
    }
  }
}

.icon-container {
  border-right: multiply-size(2px) solid $border;
}
